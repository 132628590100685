import React from "react";
import styled from "styled-components";

import { RangeSlider } from "kepler.gl/components";

const inputTheme = "secondary";
const isRanged = true;
const showInput = true;
const sliderHandleWidth = 12;
const inputSize = "small";

const RangeSliderWrapper = styled.div`
  .kg-range-slider__bar {
    background: #1a60b4;
  }
  .kg-range-slider__handle {
    background: #ffffff;
    border: 2px solid #1a60b4;
    border-radius: 50%;
  }
  .in-range {
    fill: #1a60b4 !important;
  }
`;

const ChartInput = (props) => {
  const { className, filters, value, setValue } = props;
  const filter = filters[filters.length - 1];
  const onChange = (values) => {
    setValue(values);
  };

  return (
    <RangeSliderWrapper className={className}>
      <RangeSlider
        range={filter.domain}
        value0={value[0]}
        value1={value[1]}
        step={filter.step}
        histogram={filter.histogram}
        isEnlarged={filter.enlarged}
        inputTheme={inputTheme}
        isRanged={isRanged}
        showInput={showInput}
        sliderHandleWidth={sliderHandleWidth}
        inputSize={inputSize}
        onChange={onChange}
      />
    </RangeSliderWrapper>
  );
};

export default ChartInput;
