import axios from "axios";
import { queryClient } from "../../App";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useSession } from "../../context/session";
import globalConfig from "../../utils/config";
import {
  isDiscoveryUri,
  assembleDiscoveryDatasetRequest,
} from "../../utils/discovery";

let isFirstLoading = true;
const CheckValidity = (props) => {
  const { getBearerToken } = useSession();
  const datasetsOnMap = useSelector((state) => state.keplerGl.map.visState.datasets);

  useQuery(
    ["all-datasets"],
    async () => {
      const url =
        globalConfig.demandIntelService + globalConfig.apiRoutes.datasetList;
      const config = {
        url,
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${await getBearerToken()}`,
        },
      };

      return axios(config);
    },
    {
      onSuccess: async (response) => {
        if (isFirstLoading) {
          isFirstLoading = false;
        } else {
          const bearerToken = await getBearerToken();
          const queries = queryClient.queryCache.queries;
          const datasetIdsOnMap = Object.keys(datasetsOnMap);
          const latestDatasets = Object.fromEntries(new Map(
            response.data.data.datasets.map((ds) => [ds.id, ds])
          ));

          const expiredQueries = [];
          const updatedDatasetRequests = datasetIdsOnMap.map((id) => {
            // discovery dataset, pass through
            if (isDiscoveryUri(id)) {
              return assembleDiscoveryDatasetRequest(id, bearerToken)
            }

            // dataset not longer part of Org, 
            if (!(id in latestDatasets)) return null;

            const latestDs = latestDatasets[id];
            const query = queries.find((query) => (
              query.queryKey[0] === "dataset" &&
              query.queryKey[1] === id
            ));
            if (query && query.queryKey[2] != latestDs.generationId) {
              expiredQueries.push(query);
            }

            return latestDs;
          }).filter((req) => req !== null);

          props.setDsRefs(updatedDatasetRequests);

          expiredQueries.forEach(async (query) => {
            await queryClient.invalidateQueries(query.queryKey, {refetchType: "none"});
          });
        }
      },
      onError: (error) => {
        console.error(error);
      },
      refetchInterval: 60 * 60 * 1000,
      //refetchIntervalInBackground: true,
    }
  );

  return <div></div>;
};

export default CheckValidity;
