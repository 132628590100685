import { Modal, Button } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Text from "../../../components/Text";
import SelectableBox from "../SelectableBox";
import SelectDataset from "../SelectDataset";
import DiscoveryPreview from "./DiscoveryPreview";
import { isDiscoveryDataset } from "../../../utils/tools";

const { parse } = require("json2csv");

export const ModalComponent = styled.div`
  background-color: #f6f8fa;
  padding: 48px 64px 50px;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 19px 28px 19px 64px;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

export function downloadCsv(csvString, filename) {
  var blob = new Blob([csvString]);
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveBlob(blob, filename);
  } else {
    var a = window.document.createElement("a");

    a.href = window.URL.createObjectURL(blob, {
      type: "text/plain",
    });
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

const ExportDataModal = (props) => {
  const { setIsModalVisible, isModalVisible } = props;
  const map = useSelector((state) => state.keplerGl.map);
  const datasets = Object.keys(map.visState.datasets).map((key) => {
    return map.visState.datasets[key];
  });
  const [selectedDatasetId, setSelectedDatasetId] = useState(datasets[0]?.id);
  const selectedDataset = map.visState.datasets[selectedDatasetId];
  const [selectedDataType, setSelectedDataType] = useState("filtered");
  const [isDiscoveryPreview, setIsDiscoveryPreview] = useState(false);

  const onSetDiscoveryPreview = (bool) => {
    setIsDiscoveryPreview(bool);
  };

  const onSelectDataset = (datasetId) => {
    setSelectedDatasetId(datasetId);
  };
  const isDiscovery = () => {
    const dataset = map.visState.datasets[selectedDatasetId];
    if (!dataset) {
      return null;
    }
    return isDiscoveryDataset(dataset);
  };
  const handleOk = () => {
    const fields = selectedDataset.fields;
    const firstRowArray = fields.map((item) => {
      return item.name;
    });

    const opts = { firstRowArray };
    let data = selectedDataset?.dataContainer._rows;
    if (selectedDataType === "filtered") {
      data = data.filter((item, i) =>
        selectedDataset?.filteredIndexForDomain.includes(i)
      );
    }
    const dataSource = data.map((row, index) => {
      const obj = {};
      row.forEach((item, index) => {
        obj[firstRowArray[index]] = item;
      });
      return obj;
    });
    try {
      const csv = parse(dataSource, opts);
      downloadCsv(csv, "config.csv");
    } catch (err) {
      console.error(err);
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <StyledModal
      title="Export Data"
      visible={isModalVisible}
      onCancel={handleCancel}
      width={"50%"}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="export"
          type="primary"
          onClick={handleOk}
          disabled={isDiscovery()}
        >
          Export
        </Button>,
        <Button
          key="preview"
          type="primary"
          onClick={() => onSetDiscoveryPreview(true)}
          disabled={!isDiscovery()}
        >
          Preview Discovery
        </Button>,
      ]}
    >
      <ModalComponent>
        <div className="mb-32 flex">
          {isDiscoveryPreview && (
            <DiscoveryPreview
              visible={isDiscoveryPreview}
              onSetVisible={onSetDiscoveryPreview}
              selectedDiscovery={map.visState.datasets[selectedDatasetId]}
            />
          )}
          <div>
            <Text className={"text13 mb-2"}>Dataset</Text>
            <Text className={"text7 max-width-160"}>
              Choose the datasets you want to export
            </Text>
          </div>
          <SelectDataset
            datasets={datasets}
            selectedDatasetId={selectedDatasetId}
            setSelectedDatasetId={onSelectDataset}
            size="small"
            className="ml-40"
          />
        </div>
        <div>
          <div className="flex">
            <div>
              <Text className={"text13 mb-2"}>Data Type</Text>
              <Text className={"text7 max-width-160"}>
                You can choose exporting original data or filtered data
              </Text>
            </div>
            <div className={"flex ml-40"}>
              <SelectableBox
                title={"Unfiltered Data"}
                rowNumbers={selectedDataset?.dataContainer._rows.length}
                isSelected={selectedDataType === "unfiltered"}
                onSelect={() => setSelectedDataType("unfiltered")}
              />
              <SelectableBox
                className={"ml-8"}
                title={"Filtered Data"}
                rowNumbers={selectedDataset?.filteredIndexForDomain.length}
                onSelect={() => setSelectedDataType("filtered")}
                isSelected={selectedDataType === "filtered"}
              />
            </div>
          </div>
        </div>
      </ModalComponent>
    </StyledModal>
  );
};

export default ExportDataModal;
