import React from "react";
import emptyImg from "../../../../assets/img/empty.png";
import Text from "../../../../components/Text";
const Empty = (props) => {
  const { message } = props;
  return (
    <div className="text-align-center pt-40">
      <img src={emptyImg} alt={"empty"} className="mb-12" />
      <Text className={"text16"}>{
        message ? message : "Search to see results"
      }</Text>
    </div>
  );
};

export default Empty;
