import React, { useState } from "react";
import SearchInput from "./SearchInput";
import SearchResult from "./SearchResult";
import { useQuery } from "@apollo/client";
import { GET_Discovery } from "../../../Queries/discovery";
import Spinner from "../../../components/Loading/Spinner";
import useGuards from "../../../hooks/useGuards";

const Discovery = () => {
  const [search, setSearch] = useState("");
  const [version, setVersion] = useState("");
  const { hasFeature } = useGuards();

  const onSearchChange = (text) => {
    setSearch(text);
  };
  const onSetVersion = (text) => {
    setVersion(text);
  };

  const discoveryQuery = useQuery(GET_Discovery, {
    variables: {
      input: {
        fts: search,
        odata: "release.publish_status eq 'Published' and startswith(product.internal_name, 'atlasai.rockefeller.')",
        limit: 50,
      },
    },
    skip: search.trim().length < 1,
  });

  if (!hasFeature("DISCOVERY_SEARCH")) {
    return <div>Discovery Search is not enabled for this account</div>;
  }

  const data = discoveryQuery.data?.search?.results;
  return (
    <div>
      <SearchInput value={search} onChange={onSearchChange} />
      {discoveryQuery.loading === true ? (
        <div className="text-align-center p-16">
          <Spinner size={"medium"} />
        </div>
      ) : (
        <SearchResult data={data} onSetVersion={onSetVersion} />
      )}
    </div>
  );
};

export default Discovery;
