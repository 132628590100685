import React from "react";
import { Button, Icons } from "kepler.gl/components";

const KeplerGlAddButton = (props) => {
  const { onClick, inactive = false, children } = props;
  return (
    <Button inactive={inactive} onClick={onClick} style={{ padding: 8 }}>
      <Icons.Add height="12px" />
      {children}
    </Button>
  );
};

export default KeplerGlAddButton;
