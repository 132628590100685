import React from "react";
import { Select } from "antd";

const { Option } = Select;

const SelectDataset = (props) => {
  const {
    datasets,
    setSelectedDatasetId,
    selectedDatasetId,
    size = "medium",
    className,
  } = props;
  const onDatasetSelect = (id) => {
    setSelectedDatasetId(id);
  };
  return (
    <div className={className}>
      <Select
        style={{ width: 248 }}
        onChange={onDatasetSelect}
        value={selectedDatasetId}
        size={size}
      >
        {datasets.map((dataset) => {
          return (
            <Option value={dataset.id} key={dataset.id}>
              {dataset.label}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectDataset;
