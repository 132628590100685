import { useAuth0 } from "@auth0/auth0-react";

import { useSession } from "../context/session";

const key = "https://aperture.atlasai.co/claims";

function useGuards() {
  const { accessToken } = useSession();
  const { user } = useAuth0();

  const hasXFactor = (context, xFactor) => {
    try {
      return context.includes(xFactor);
    } catch (error) {
      return false;
    }
  };

  const hasFeature = (flag) => {
    return hasXFactor(
      user[key].app_metadata.modules?.["demand_intel"].features,
      flag
    );
  };

  const hasPermission = (permission) => {
    return hasXFactor(accessToken?.permissions, permission);
  };

  const hasRole = (role) => {
    return hasXFactor(user[key].roles, role);
  };

  return { hasFeature, hasPermission, hasRole };
}

export default useGuards;
