import styled, { css } from "styled-components";
export const LayerManagerWrapper = styled.div`
  .source-data-catalog,
  .add-data-button,
  .side-panel-divider {
    display: none;
  }
  .add-layer-button {
    width: 110px;
    white-space: nowrap;
    padding: 8px;
  }
  .kg-range-slider__bar {
    background: #1a60b4;
  }
  .kg-range-slider__handle {
    background: #ffffff;
    border: 2px solid #1a60b4;
    border-radius: 50%;
  }
  .side-panel-panel__label {
    font-size: 1.1em;
  }

  .layer__title__editor {
    color: #24292e;
    font-size: 0.875rem;
  }
  .layer__title__type {
    font-size: 0.75rem;
    color: #959da5;
  }
  ${(props) =>
    !props.canAdd &&
    css`
      .remove-dataset,
      .add-layer-button {
        display: none;
      }
    `}
`;

export const FilterManagerWrapper = styled.div`
  .source-data-catalog,
  .add-data-button,
  .side-panel-divider {
    display: none;
  }
  .side-panel-section > .filter-panel {
    border: 1px solid #e1e4e8;
    border-radius: 2px;
    padding-right: 0.1rem;
    padding-bottom: 0.1rem;
  }
  .add-filter-button {
    display: none;
  }
  .dataset-name {
    font-size: 1.2em;
  }
  .kg-range-slider__bar {
    background: #1a60b4;
  }
  .kg-range-slider__handle {
    background: #ffffff;
    border: 2px solid #1a60b4;
    border-radius: 50%;
  }
  .chickleted-input > div,
  .side-panel-panel__label {
    font-size: 0.9em;
  }
`;

export const DataManagerWrapper = styled.div`
  .layer-manager > .side-panel-divider,
  .layer-manager > .side-panel-section {
    display: none;
  }

  .add-data-button {
    width: 110px;
    padding: 8px;
  }

  ${(props) =>
    !props.canAdd &&
    css`
      .add-data-button {
        display: none;
      }
    `}

  ${(props) =>
    !props.canDelete &&
    css`
      .remove-dataset {
        display: none;
      }
    `}
`;

export const InteractionManagerWrapper = styled.div`
  .interaction-panel ~ .interaction-panel {
    display: none;
  }
  .source-data-tag {
    margin-bottom: 8px;
    align-items: center;
  }
  .dataset-color {
    width: 16px;
    height: 16px;
  }
  .dataset-name {
    line-height: 15.6px;
    font-size: 12px;
    color: #24292e;
  }
  .clear-all {
    color: #1a60b4 !important;
    border: none;
    font-weight: 400;
    line-height: 15.6px;
  }
`;

export const MapManagerWrapper = styled.div`
  .add-map-style-button {
    display: none;
  }
  .map-style__layer-group {
    background-color: #f6f8fa;
  }
`;
