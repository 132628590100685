import { TimeRangeSlider } from "kepler.gl/components";
import React from "react";
import styled from "styled-components";
import {
  animationWindow,
  bins, hideTimeTitle, id,
  isAnimatable,
  isAnimating,
  isEnlarged,
  lineChart,
  plotType, timeFormat,
  timezone,
  //   value,
  yAxis
} from "./data";

const TimeSliderWrapper = styled.div`
  .kg-range-slider__bar {
    background: #1a60b4;
  }
  .kg-range-slider__handle {
    background: #ffffff;
    border: 2px solid #1a60b4;
    border-radius: 50%;
  }
  .in-range {
    fill: #1a60b4 !important;
  }
`;

const TimeInput = (props) => {
  const { className, filters, value, setValue } = props;
  const filter = filters[filters.length - 1];
  const onChange = (values) => {
    setValue(values);
  };

  return (
    <TimeSliderWrapper>
      <TimeRangeSlider
        animationWindow={animationWindow}
        bins={bins}
        domain={filter.domain}
        hideTimeTitle={hideTimeTitle}
        histogram={filter.histogram}
        id={id}
        isAnimatable={isAnimatable}
        isAnimating={isAnimating}
        isEnlarged={isEnlarged}
        lineChart={lineChart}
        plotType={plotType}
        speed={filter.speed}
        step={filter.step}
        timeFormat={timeFormat}
        timezone={timezone}
        value={value}
        yAxis={yAxis}
        onChange={onChange}
        toggleAnimation={() => { }}
      />
    </TimeSliderWrapper>
  );
};

export default TimeInput;
