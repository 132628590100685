import React, { useState, useEffect } from "react";
import Header from "./Header";
import { ignoredColumns } from "../../utils/constants.js";
import DatasetTable from "./DatasetTable";
import Actions from "./Actions";

const DataExplorer = (props) => {
  const { map } = props;
  const datasets = Object.values(map.visState.datasets);
  const filters = map.visState.filters;
  const [selectedDatasetId, setSelectedDatasetId] = useState(datasets[0]?.id);
  const selectedDataset = map.visState.datasets[selectedDatasetId];
  const datasetIdList = datasets.map((dataset) => dataset.id);
  const columns = selectedDataset?.fields
    .filter(
      (field) =>
        !(
          ignoredColumns.includes(field.name.toLowerCase()) ||
          field.analyzerType === "GEOMETRY"
        )
    )
    .map((field) => {
      let row = {
        title: field.name,
        dataIndex: field.name,
        key: field.name,
        // width: field.analyzerType === "GEOMETRY" ? "100px" : "30px",
      };
      if (field.analyzerType === "INT" || field.analyzerType === "FLOAT") {
        row = {
          ...row,
          sorter: (record1, record2) => {
            return record1[field.name] - record2[field.name];
          },
        };
      } else if (field.analyzerType === "STRING") {
        row = {
          ...row,
          sorter: (record1, record2) => {
            const a = record1[field.name];
            const b = record2[field.name];
            return a.localeCompare(b);
          },
        };
      } else if (field.analyzerType === "BOOLEAN") {
        row = {
          ...row,
          sorter: (record1, record2) => {
            const x = record1[field.name];
            const y = record2[field.name];
            return x === y ? 0 : x ? -1 : 1;
          },
        };
      } else if (field.analyzerType === "GEOMETRY") {
        row = {
          ...row,
          render: (text) => {
            return (
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  maxWidth: 100,
                  minWidth: "100%",
                }}
                title={text}
              >
                {text}
              </div>
            );
          },
        };
      }
      return row;
    });

  useEffect(() => {
    setSelectedDatasetId(datasetIdList[0]);
  }, [JSON.stringify(datasetIdList)]);

  const filteredRowsWithoutTime = selectedDataset?.dataContainer._rows
    .filter((item, i) => selectedDataset.filteredIndexForDomain.includes(i))
    .map((row, index) => {
      const obj = {};
      row.forEach((item, index) => {
        const field = selectedDataset.fields[index];
        if (!field) return;
        if (!item) {
          // wo don't want to show null in table
          item = "";
        }
        obj[field.name] =
          typeof item === "string" ? item : JSON.stringify(item);
      });
      obj.key = index;
      return obj;
    });

  const toTimestamp = (strDate) => {
    const dt = new Date(strDate).getTime();
    return dt;
    // return dt / 1000;
  };

  const filterByTime = (dataset) => {
    let filteredData = dataset;
    const timeFilters = filters.filter(
      (item) =>
        item.dataId.includes(selectedDatasetId) && item.type === "timeRange"
    );
    timeFilters.forEach((timeFilter) => {
      const timeFilterName = timeFilter.name[0];
      filteredData = filteredData.filter((item) => {
        const valueInTimestampFormat = toTimestamp(item[timeFilterName]);
        return (
          valueInTimestampFormat >= timeFilter.value[0] &&
          valueInTimestampFormat <= timeFilter.value[1]
        );
      });
    });
    return filteredData;
  };
  const filteredData = filterByTime(filteredRowsWithoutTime);
  return (
    <div>
      <Header
        datasets={datasets}
        selectedDatasetId={selectedDatasetId}
        setSelectedDatasetId={setSelectedDatasetId}
        dataset={selectedDataset}
        filters={filters}
        filteredDatasetLength={filteredData?.length}
        isExportDataDisable={!filteredData}
      />
      {/* <Actions isExportDataDisable={!filteredData} /> */}
      <DatasetTable dataSource={filteredData} columns={columns} />
    </div>
  );
};

export default DataExplorer;
