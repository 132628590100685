export const animationWindow = "free";
export const bins = undefined;
export const domain = [1421280092000, 1421366399000];
export const hideTimeTitle = undefined;
export const histogram = [
  { count: 1906, x0: 1421280092000, x1: 1421282000000 },

  { count: 1089, x0: 1421282000000, x1: 1421284000000 },

  { count: 1163, x0: 1421284000000, x1: 1421286000000 },
  { count: 1117, x0: 1421286000000, x1: 1421288000000 },
  { count: 335, x0: 1421288000000, x1: 1421290000000 },
  { count: 340, x0: 1421290000000, x1: 1421292000000 },
  { count: 170, x0: 1421292000000, x1: 1421294000000 },
  { count: 328, x0: 1421294000000, x1: 1421296000000 },
  { count: 16, x0: 1421296000000, x1: 1421298000000 },
  { count: 0, x0: 1421298000000, x1: 1421300000000 },
  { count: 1540, x0: 1421300000000, x1: 1421302000000 },
  { count: 1208, x0: 1421302000000, x1: 1421304000000 },
  { count: 2422, x0: 1421304000000, x1: 1421306000000 },
  { count: 2323, x0: 1421306000000, x1: 1421308000000 },
  { count: 2195, x0: 1421308000000, x1: 1421310000000 },
  { count: 3649, x0: 1421310000000, x1: 1421312000000 },
  { count: 3042, x0: 1421312000000, x1: 1421314000000 },
  { count: 1856, x0: 1421314000000, x1: 1421316000000 },
  { count: 2272, x0: 1421316000000, x1: 1421318000000 },
  { count: 1996, x0: 1421318000000, x1: 1421320000000 },
  { count: 2680, x0: 1421320000000, x1: 1421322000000 },
  { count: 2791, x0: 1421322000000, x1: 1421324000000 },
  { count: 2028, x0: 1421324000000, x1: 1421326000000 },
  { count: 2962, x0: 1421326000000, x1: 1421328000000 },
  { count: 2420, x0: 1421328000000, x1: 1421330000000 },
  { count: 2925, x0: 1421330000000, x1: 1421332000000 },
  { count: 1796, x0: 1421332000000, x1: 1421334000000 },
  { count: 3134, x0: 1421334000000, x1: 1421336000000 },
  { count: 1816, x0: 1421336000000, x1: 1421338000000 },
  { count: 3586, x0: 1421338000000, x1: 1421340000000 },
  { count: 2695, x0: 1421340000000, x1: 1421342000000 },
  { count: 2723, x0: 1421342000000, x1: 1421344000000 },
  { count: 2444, x0: 1421344000000, x1: 1421346000000 },
  { count: 2754, x0: 1421346000000, x1: 1421348000000 },
  { count: 2499, x0: 1421348000000, x1: 1421350000000 },
  { count: 3914, x0: 1421350000000, x1: 1421352000000 },
  { count: 3882, x0: 1421352000000, x1: 1421354000000 },
  { count: 3527, x0: 1421354000000, x1: 1421356000000 },
  { count: 2533, x0: 1421356000000, x1: 1421358000000 },

  { count: 3851, x0: 1421358000000, x1: 1421360000000 },
  { count: 4299, x0: 1421360000000, x1: 1421362000000 },
  { count: 3927, x0: 1421362000000, x1: 1421364000000 },
  { count: 2632, x0: 1421364000000, x1: 1421366000000 },
  { count: 1201, x0: 1421366000000, x1: 1421366399000 },
];
export const id = "xsneyydgc";
export const isAnimatable = undefined;
export const isAnimating = false;
export const isEnlarged = false;
export const lineChart = undefined;
export const plotType = "histogram";
export const speed = 1;
export const step = 1000;
export const timeFormat = "L LTS";
export const timezone = undefined;
export const value = [1421280092000, 1421366399000];
export const yAxis = null;
