//import { useEffect, useState } from "react";
import axios from "axios";
import KeplerGlSchema from "kepler.gl/schemas";

import { useDatasetList } from "./calls/useDatasetList";
import { useSession } from "../context/session";
import { views } from "../constants/env";
import config from "../utils/config";
import {
  isDiscoveryUri,
  assembleDiscoveryDatasetRequest
} from "../utils/discovery";

export const useProject = () => {
  const { getBearerToken } = useSession();
  const datasetList = useDatasetList(null);

  const getProjectConfig = async (envId) => {
    const url = `${config.demandIntelService}${config.apiRoutes.getSingleEnv}/${envId}`;
    const request = {
      url: url,
      method: "GET",
      headers: {
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    };

    try {
      return await axios(request);
    }
    catch (e) {
      if (e.response.status === 404) {
        console.log("Missing Project config. Must be a brand new Project");
        return null;
      }
      else {
        return false;
      }
    }
  };

  const getViewConfig = async (envId, viewId) => {
    const request = {
      url: `${config.demandIntelService}${config.apiRoutes.getProjectView(
        envId,
        viewId
      )}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    };
    const response = await axios(request);
    return response.data;
  };

  const getMapConfig = async (envId, viewId, currentEnv) => {
    const viewIds = [viewId, views.TEAM_VIEW];
    const orgDatasets = (
      datasetList.isFetched === true
        ? datasetList.data
        : await datasetList.getDatasetList()
    )?.datasets || [];

    for (const vId of viewIds.filter(
      (value, index, self) => self.indexOf(value) === index
    )) {
      try {
        const response = await getViewConfig(envId, vId);
        const bearerToken = await getBearerToken();

        // reduce provisioned datasets to just those used in this view
        // also stub out Discovery URLs
        const datasets = response.data.datasetIds.map(
          (datasetId) => {
            const found = orgDatasets.find((value) => value.id === datasetId);
            if (found !== undefined) return found;
            if (!isDiscoveryUri(datasetId)) return null;

            return assembleDiscoveryDatasetRequest(datasetId, bearerToken);
          }
        ).filter((v) => v !== null);

        return {
          projectId: envId,
          viewId: viewId,
          majorVersion: response.data.majorVersion,
          minorVersion: response.data.minorVersion,
          version: response.data.version,
          datasets: datasets,
          mapConfig: response.data.metadata,
        };
      } catch (e) {
        console.error("Error getting view config", vId);
        console.error(e);
      }
    }

    if (!currentEnv) {
      console.log("Cannot go old school for view config as no currentEnv set");
      return null;
    }

    // still here, go old school
    try {
      //console.log("Going old school for view config", currentEnv.data.data.url);
      const response = await axios.get(currentEnv.data.data.url);
      return {
        projectId: envId,
        viewId: viewId,
        datasets: currentEnv.data.data.datasets,
        mapConfig: response.data,
      };
    } catch (e) {
      if (e.response?.status === 404) {
        console.log("Missing old-school visualization config");
        return null;
      } else {
        console.error(e.message);
      }
    }

    return false;
  };

  // always get fresh copy of view
  const loadProjectViewConfig = async (envId, viewId) => {
    const viewName = viewId === views.MY_VIEW ? "my view" : "team view";
    const errorMessage = `Error loading "${viewName}". Please try again`;

    const noConfig = {
      noConfig: true,
      config: {
        datasets: [],
        majorVersion: 0,
        minorVersion: 0,
        version: 0,
        mapConfig: {},
        projectId: envId,
        viewId: viewId,
      },
      parsedConfig: {
        mapState: {},
        mapStyle: {},
        visState: {},
      },
    };

    const currentEnv = await getProjectConfig(envId);
    if (currentEnv === false) {
      return {
        error: errorMessage,
      };
    }

    const cfg = await getMapConfig(envId, viewId, currentEnv);
    if (cfg === false) {
      return {
        error: errorMessage,
      };
    }
    else if (cfg === null) {
      return noConfig;
    }

    try {
      const parsedConfig = KeplerGlSchema.parseSavedConfig(cfg.mapConfig);
      return {
        config: cfg,
        parsedConfig: sanitizeParsedConfig(parsedConfig),
      };
    } catch (e) {
      console.error(e);
      return {
        error: errorMessage,
      };
    }
  };

  const sanitizeParsedConfig = (parsedConfig) => {
    if (!!parsedConfig?.visState?.filters) {
      parsedConfig.visState.filters = parsedConfig.visState.filters.map((filter) => {
        if (filter.name.length < 1) return false;
        return filter;
      }).filter((filter) => filter !== false);
    }

    return parsedConfig;
  };

  return {
    loadProjectViewConfig,
  };
};
