import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";

import styled from "styled-components";
import Header from "../../components/Header/Header";
import Text from "../../components/Text";
import ProjectCard from "./ProjectCard";
import Resources from "./Resources";
import GlobalLoading from "../../components/GlobalLoading";
import useEnvs from "../../hooks/calls/useEnvs";
import { setCurrentEnv } from "../../store/actions";
import { views } from "../../constants/env";
import { useSession } from "../../context/session";
import { redirectIfAnyCartoProject } from "../../utils/redirect";

const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const YourEnvironments = () => {
  const { data: envList, isLoading: isEnvListLoading } = useEnvs();
  const { setProjectView } = useSession();
  const dispatch = useDispatch();

  const { user } = useAuth0();
  const setEnvironment = (envId) => {
    dispatch(setCurrentEnv(envId));
    setProjectView(views.MY_VIEW);
  };

  useEffect(() => {
    if (!envList) return;
    redirectIfAnyCartoProject(envList);
  }, [envList]);

  if (isEnvListLoading) {
    return (
      <>
        <GlobalLoading />
      </>
    );
  }

  return (
    <div>
      <Header page="environments" />
      <div className="p-48">
        <Text className="text19 mb-24">Hello, {user.name}</Text>
        <Text className="text15 mb-4">Your Environments</Text>
        <Text className="text16 mb-16">
          See all of your team projects below
        </Text>
        <CardWrapper>
          {envList?.map((env) => {
            return (
              <ProjectCard
                key={env.id}
                status={env.status}
                name={env.name}
                description={env.description}
                imageLink={env.imageLink}
                lastUpdateDate={env.lastUpdateDate}
                envId={env.id}
                env={env}
                setEnvironment={setEnvironment}
              />
            );
          })}
        </CardWrapper>
      </div>
      <Resources />
    </div>
  );
};

export default YourEnvironments;
