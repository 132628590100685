import React from "react";
const getDirection = (dir) => {
  if (dir === "right") return 270;
  if (dir === "left") return 90;
  if (dir === "up") return 180;
  return 0;
};

const Arrow = ({
  fill = "#24292E",
  width = 10.283,
  height = 6,
  direction = "down",
  margin = "",
  onClick = () => {},
  className = "",
}) => {
  const dir = getDirection(direction);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 10.283 6"
      style={{
        margin: margin,
        transform: `rotate(${dir}deg)`,
        transition: "transform 0.25s ease-out",
      }}
      onClick={onClick}
      className={className}
    >
      <path
        fill={fill}
        d="M1.231 10.074l4.623-4.583a.49.49 0 0 0 0-.7L1.231.209a.726.726 0 0 0-1.02 0 .712.712 0 0 0 0 1.011l3.954 3.922-3.954 3.92a.712.712 0 0 0 0 1.012.726.726 0 0 0 1.02 0"
        transform="rotate(90 5.141 5.142)"
      />
    </svg>
  );
};

export default Arrow;
