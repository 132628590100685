import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header/Header";
import CustomKeplerGl from "./CustomKeplerGl";

const Home = () => {
  const [headerHeight, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight);
  }, []);
  return (
    <>
      <div ref={ref}>
        <Header />
      </div>

      <CustomKeplerGl headerHeight={headerHeight} temp={false} />
    </>
  );
};

export default Home;
