import React from "react";
import { Select } from "antd";

const { Option } = Select;

const children = [];
for (let i = 10; i < 36; i++) {
  children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
}

const MultiSelect = (props) => {
  const { className, filters, value, setValue } = props;
  const filter = filters[filters.length - 1];

  const onChange = (values) => {
    setValue(values);
  };

  return (
    <div className={className}>
      <Select
        mode="multiple"
        allowClear
        style={{ width: "100%" }}
        placeholder="Select variable"
        onChange={onChange}
      >
        {filter.domain.map((domain) => {
          return (
            <Option key={domain} value={domain}>
              {domain}
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default MultiSelect;
