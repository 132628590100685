import { LoadDataModalFactory } from "kepler.gl/components";
import LoadDatasets from "./LoadDatasets";
import Discovery from "./Discovery";
//import DiscoveryTab from "./Discovery/tab";
const customLoadDataModalFactory = (...deps) => {
  const LoadDataModal = LoadDataModalFactory(...deps);
  const tabs = {
    datasets: {
      id: "loadDatasets.projectFiles",
      label: "loadDatasets.projectFiles",
      elementType: LoadDatasets,
    },
    discovery: {
      id: "loadDatasets.discovery",
      label: "loadDatasets.discovery",
      elementType: Discovery,
      //tabElementType: DiscoveryTab,
    },
  };
  LoadDataModal.defaultProps = {
    loadingMethods: [tabs.datasets, tabs.discovery],
  };

  return LoadDataModal;
};
customLoadDataModalFactory.deps = LoadDataModalFactory.deps;
export default customLoadDataModalFactory;
