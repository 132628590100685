import styled from "styled-components";

export const LayerHoverInfoWrapper = styled.div`
  .map-popover__layer-name {
    color: #262626;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.2px;
  }
  .row__name {
    color: #262626;
    font-size: 12px;
    line-height: 16.8px;
    font-weight: 400;
  }
  .row__value {
    color: #262626 !important;
    font-size: 12px !important;
    line-height: 16.8px !important;
    font-weight: 500 !important;
  }
`;
