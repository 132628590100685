import { Divider, Button, Table } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { addDataToMap, toggleModal } from "kepler.gl/actions";
import GlobalLoading from "../../../../components/GlobalLoading";
import {
  assembleDiscoveryDataset,
  includeLoadableDiscoveryAssets,
} from "../../../../utils/discovery";
import {
  processDataForKepler,
} from "../../../../utils/processors";
import {
  convertObjectToCSV,
  uniqueArray
} from "../../../../utils/tools";

const Label = styled.div`
  border: 1px solid #ffadd2;
  background-color: #fff0f6;
  max-width: 150px;
  color: #eb2f96;
  font-size: 12px;
  padding: 1px, 8px, 1px, 8px;
  text-align: center;
  border-radius: 2px;
`;

const DiscoveryTable = (props) => {
  const { data, onSetVersion } = props;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState("");
  const dataSource = [];
  data.forEach((result) => {
    const product = result.product
    product.releases.forEach((release) => {
      const row = {
        datasetName: release.name || product.displayName || product.internalName,
        id: release.id,
        key: release.id,
        status: release.publishStatus,
        version: release.version,
        url: release.uris[0],
      };
      dataSource.push(row);
    });
  });
  const columns = [
    {
      title: "Dataset name",
      dataIndex: "datasetName",
    },
    {
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "published", value: "Published" },
        { text: "unpublished", value: "NotPublished" },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Version",
      dataIndex: "version",
      render: (text) => <Label>{text}</Label>,
      filters: uniqueArray(
        dataSource.map((row) => {
          return {
            text: row.version,
            value: row.version,
          };
        }),
        "value"
      ),
      onFilter: (value, record) => record.version === value,
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const assembleDataset = async (releaseId) => {
    const initialValue = [null, null, null];
    const [ result, product, release ] = data.reduce(
      (finalResult, result) => {
        if (finalResult !== initialValue) return finalResult;

        const product = result.product;
        const release = product.releases.find(
          (r) => r.id === releaseId
        );
        if (release === undefined) return initialValue;

        return [result, product, release];
      },
      initialValue
    );
    if (result === null) return;

    const ds = assembleDiscoveryDataset(product, release);

    // minimal representation of Discovery Dataset
    const dataItem = processDataForKepler({
      fileType: ds.type,
      dataset: ds.data,
      datasetName: ds.label,
    });
    if (dataItem === false) return;

    await includeLoadableDiscoveryAssets({
      keplerDataset: dataItem,
      response: result,
      label: ds.label,
      releaseId: release.id,
    });

    return {
      data: dataItem,
      info: {
        label: ds.label,
        id: ds.id,
      },
    };
  }

  const addSelectedItemsToMap = async () => {
    setIsLoading(true);

    const datasets = await Promise.all(selectedRowKeys.map(
      (releaseId) => assembleDataset(releaseId)
    ));

    datasets.forEach((dataset) => {
      if (!dataset) return;

      try {
        dispatch(
          addDataToMap({
            datasets: dataset,
            options: {
              autoCreateLayers: false,
              centerMap: false
            },
          })
        );
      }
      catch (e) {
        console.error("Error adding Datasets to Map:", dataset);
        message.error(`Error adding Datasets to Map: ${dataset.info.label}`);
      }
    });

    setIsLoading(false);
  };

  return (
    <div className="pt-16">
      {isLoading && <GlobalLoading />}
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={dataSource}
        pagination={true}
      />
      <Divider className={"m-24-0-10"} />
      <div className={"text-align-right"}>
        <Button
          onClick={() => {
            dispatch(toggleModal(""));
          }}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={selectedRowKeys.length === 0}
          className="ml-8"
          onClick={addSelectedItemsToMap}
        >
          Add data
        </Button>
      </div>
    </div>
  );
};

export default DiscoveryTable;
