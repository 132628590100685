import { message } from "antd";
import Processors from "kepler.gl/processors";

import { randomString } from "./tools";

export const processDataForKepler = (props) => {
  const {fileType, dataset, datasetName} = props;
  let loadedData;

  try {
    loadedData = (
      fileType === "json"
        ? Processors.processGeojson(dataset)
        : Processors.processCsvData(dataset)
    );
  }
  catch (e) {
    console.error("Error processing Dataset:", fileType, datasetName);
    if (message !== undefined) {
      message.error(
        `Error processing Dataset: ${datasetName}`
      );
    }

    return false;
  }

  return loadedData;
}

export const appendToKeplerDataset = (dataset, toAppend) => {
  const oldNumFields = dataset.fields.length;
  const fieldIndexMap = {};

  // merge in fields
  toAppend.fields.forEach((field, idx) => {
    const existingField = dataset.fields.find((f) => field.id === f.id);
    if (existingField !== undefined) {
      if (existingField.type === field.type) {
        fieldIndexMap[idx] = dataset.fields.indexOf(existingField);
        return;
      }

      const newFieldId = `${field.id}_${randomString()}`
      field.displayName = field.id = field.name = newFieldId;
    }

    fieldIndexMap[idx] = dataset.fields.length;
    dataset.fields.push(field);
  });

  // re-index field.fieldIdx
  dataset.fields.forEach((field, idx) => field.fieldIdx = idx);

  const newNumFields = dataset.fields.length;

  // fill in data rows with nulls of existing columns
  dataset.rows.forEach((row) => {
    row.length = newNumFields;
    row.fill(null, oldNumFields);
  });

  // add new rows
  dataset.rows.push(...(
    toAppend.rows.map((row) => {
      const newRow = new Array(newNumFields);
      newRow.fill(null);

      row.forEach((element, idx) => {
        newRow[fieldIndexMap[idx]] = element;
      });

      return newRow;
    })
  ));
}
