import { useState } from "react";
import { Select, Modal, Form, Input, message } from "antd";
import { useMutation } from "react-query";
import { ReactComponent as ThreeDotsIcon } from "../../../../assets/img/three-dots.svg";
import Text from "../../../../components/Text";
import { updateEnvironment } from "../../../../calls/env";
import { useAuth0 } from "@auth0/auth0-react";

const { Option } = Select;

const ProjectDetailModal = (props) => {
  const {
    name,
    description,
    status,
    envId,
    env,
    setIsModalVisible,
    isModalVisible,
  } = props;
  const { getAccessTokenSilently } = useAuth0();

  const [form] = Form.useForm();
  const mutation = useMutation(updateEnvironment, {
    onSuccess: (data) => {
      setIsModalVisible(false);
    },
  });

  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onFinish = async (values) => {
    const newEnv = { ...env };
    newEnv.name = values.name;
    newEnv.description = values.description;
    newEnv.status = values.status;
    // return;
    const token = await getAccessTokenSilently();
    mutation.mutate({ token, newEnv, envId });
  };
  return (
    <>
      <Modal
        title={<Text className={"text24"}>Project Details</Text>}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Save"}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{ name, description, status }}
        >
          <Form.Item
            name="name"
            label={"Title"}
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input placeholder="Title" />
          </Form.Item>
          <Form.Item
            name="description"
            label={"Description"}
            rules={[
              { required: true, message: "Please input the description!" },
            ]}
          >
            <Input placeholder="Description" />
          </Form.Item>
          <Form.Item
            name="status"
            label={"Status"}
            rules={[{ required: true, message: "Please input the status!" }]}
          >
            <Select
              style={{ width: 120 }}
              onChange={(status) => form.setFieldsValue({ status })}
            >
              <Option value="ACTIVE">Active</Option>
              <Option value="ARCHIVED">Archived</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ProjectDetailModal;
