import React from "react";
import Text from "../../../../components/Text";
import styled from "styled-components";

const Wrapper = styled.div`
  border-bottom: 1px solid #e1e4e8;
  padding: 16px 0 15px 24px;
`;

const ActiveWrapper = styled(Wrapper)`
  background-color: #f0f0f0;
`;

const DatasetDetailsListItem = (props) => {
  const { item, isActive, onClick } = props;
  const ThisWrapper = isActive === true ? ActiveWrapper : Wrapper;

  return (
    <ThisWrapper>
      <Text className="text18 fw-medium">{item.label}</Text>
      <Text className="text25 mb-6">{item.description}</Text>
      <Text className="text26 pointer" onClick={onClick}>
        View details
      </Text>
    </ThisWrapper>
  );
};

export default DatasetDetailsListItem;
