import React from "react";
import styled from "styled-components";
import Title from "antd/lib/typography/Title";
import DatasetDetailsListItem from "./DatasetDetailsListItem";
import Text from "../../../../components/Text";

const HeaderText = styled(Text)`
  padding-left: 24px;
`;

const DatasetDetailsList = (props) => {
  const { datasets, activeDatasetId, setSelectedDatasetIndex } = props;
  return (
    <div>
      <HeaderText className="text27 mb-24 fw-semibold">Data Dictionary</HeaderText>
      {datasets.map((item, index) => {
        return (
          <DatasetDetailsListItem
            key={index}
            item={item}
            isActive={item.id === activeDatasetId}
            onClick={() => {
              setSelectedDatasetIndex(index);
            }}
          />
        );
      })}
    </div>
  );
};

export default DatasetDetailsList;
