import React from "react";
import { Select, Tag } from "antd";
import styled, { css } from "styled-components";
import Text from "../../components/Text";
import { getDataTypeColor } from "../../utils/tools";

const { Option } = Select;

const TagWrapper = styled.div`
  width: 4.5rem;
`;

const SelectFieldBar = (props) => {
  const { fields, onFieldSelect, className } = props;

  return (
    <div className={className}>
      <Select
        showSearch
        placeholder="Select variable"
        optionFilterProp="children"
        onChange={onFieldSelect}
        filterOption={(input, option) =>
          option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        style={{ width: "100%" }}
      >
        {fields?.filter(
          (field) => field.type !== "geojson"
        ).map(
          (field, i) => {
            return (
              <Option value={field.id} key={field.id}>
                <div className={"flex-s-c"}>
                  <TagWrapper>
                    <Tag color={getDataTypeColor(field.type)}> {field.type} </Tag>
                  </TagWrapper>
                  <Text className={"ml-8 lowercase"}>{field.name}</Text>
                </div>
              </Option>
            );
          }
        )}
      </Select>
    </div>
  );
};

export default SelectFieldBar;
