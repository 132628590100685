import React from "react";
import { Table, Tag } from "antd";
import Title from "antd/lib/typography/Title";
import { getDataTypeColor } from "../../../../utils/tools";

const DatasetDetailDrawer = (props) => {
  const { data, visibility, handleOnClose, returnToDatasetListView } = props;

  const columns = [
    {
      title: "Field name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Data type",
      key: "type",
      dataIndex: "type",
      render: (val) => <Tag color={getDataTypeColor(val)}> {val} </Tag>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <div>
      <Title level={4} className={"title4-style"}>
        {data.label}
      </Title>
      <p>{data.description}</p>
      <Table columns={columns} dataSource={data.fields}></Table>
    </div>
  );
};

export default DatasetDetailDrawer;
