import React from "react";
import styled from "styled-components";
import Text from "../../components/Text";
import FieldValue from "./FieldValue";
import SelectFieldBar from "./SelectFieldBar";

const Wrapper = styled.div`
  border: 1px solid #e1e4e8;
  background-color: #fff;
  border-radius: 2px;
  padding: 16px 12px;
  margin-top: 1px;
  width: 280px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05),
    0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
`;

const SelectField = (props) => {
  const { fields, selectedField, onFieldSelect, filters, closePopup } = props;
  const filter = filters[filters.length - 1];

  return (
    <div>
      <Text className={"text11 mb-8"}>Data attribute</Text>
      <SelectFieldBar
        fields={fields}
        onFieldSelect={onFieldSelect}
        selectedField={selectedField}
        className={"mb-16"}
      />
      {filter?.type && (
        <FieldValue filter={filter} closePopup={closePopup} filters={filters} />
      )}
    </div>
  );
};

export default SelectField;
