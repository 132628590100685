import styled, { css } from "styled-components";
import { Button } from "antd";

const StyledButton = styled(Button)`
  ${(props) =>
    props.$active &&
    css`
      background-color: #f1f8ff;
      color: #225e96;
    `}
  ${(props) => {
    switch (props.type) {
      case "primary":
        return css`
          background-color: #1a60b4;
        `;
      case "info":
        return css`
          background: #f6f8fa;
          color: #444d56;
        `;
      default:
        break;
    }
  }}
`;

export const TopLevelTabButton = styled(StyledButton)`
  &:hover {
    color: #1a60b4;
    background: transparent;
  }
`;

export default StyledButton;
