import React from "react";
import {
  SidebarFactory,
  Icons,
  LayerManagerFactory,
  FilterManagerFactory,
  SourceDataCatalogFactory,
  AddDataButtonFactory,
  InteractionManagerFactory,
  MapManagerFactory,
} from "kepler.gl/components";
import styled from "styled-components";
import Sidepanel from "../../components/Sidepanel";

const StyledSideBarContainer = styled.div`
  .side-panel--container {
    transform-origin: top left;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    width: 312px;
  }
  .side-bar {
    /* width: 320px; */
  }
  .side-bar__inner {
    background: white;
    padding-left: 16px;
    padding-right: 16px;
  }
  .side-bar__close {
    display: none;
  }
`;

const StyledCloseButton = styled.div`
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.primaryBtnBgd};
  color: ${(props) => props.theme.primaryBtnColor};
  display: flex;
  height: 46px;
  position: absolute;
  top: 0;
  width: 80px;
  right: 0;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.primaryBtnBgdHover};
  }
`;

const CloseButtonFactory = () => {
  const CloseButton = ({ onClick, isOpen }) => (
    <StyledCloseButton className="side-bar__close" onClick={onClick}>
      <Icons.ArrowRight
        height="18px"
        style={{
          transform: `rotate(${isOpen ? 180 : 0}deg)`,
          marginLeft: isOpen ? 0 : "30px",
        }}
      />
    </StyledCloseButton>
  );
  return CloseButton;
};
CustomSidebarFactory.deps = [
  CloseButtonFactory,
  LayerManagerFactory,
  FilterManagerFactory,
  SourceDataCatalogFactory,
  AddDataButtonFactory,
  InteractionManagerFactory,
  MapManagerFactory,
];

// Custom sidebar will render kepler.gl default side bar
// adding a wrapper component to edit its style
function CustomSidebarFactory(
  CloseButton,
  LayerManager,
  FilterManager,
  SourceDataCatalog,
  AddDataButton,
  InteractionManager,
  MapManager
) {
  const SideBar = SidebarFactory(CloseButton);
  const CustomSidebar = (props) => {
    return (
      <StyledSideBarContainer>
        <SideBar>
          <Sidepanel
            uiStateActions={props.uiStateActions}
            visStateActions={props.visStateActions}
            layerManager={
              <LayerManager
                showDatasetTable={props.visStateActions.showDatasetTable}
                removeDataset={props.visStateActions.removeDataset}
                showAddDataModal={() => {
                  props.uiStateActions.toggleModal("addData");
                }}
                datasets={props.datasets}
                layers={props.layers}
                layerOrder={props.layerOrder}
                layerBlending={props.layerBlending}
                layerClasses={props.layerClasses}
                visStateActions={props.visStateActions}
                uiStateActions={props.uiStateActions}
              />
            }
            filterManager={
              <FilterManager
                {...props}
                layers={props.layers}
                showDatasetTable={props.visStateActions.showDatasetTable}
              />
            }
            datasetManager={
              <div>
                <SourceDataCatalog
                  showDatasetTable={props.visStateActions.showDatasetTable}
                  removeDataset={props.visStateActions.removeDataset}
                  showAddDataModal={() => {
                    props.uiStateActions.toggleModal("addData");
                  }}
                  layerBlending={props.layerBlending}
                  layerClasses={props.layerClasses}
                  layers={props.layers}
                  visStateActions={props.visStateActions}
                  datasets={props.datasets}
                  layerOrder={props.layerOrder}
                  uiStateActions={props.uiStateActions}
                  showDeleteDataset={true}
                />
                <AddDataButton
                  onClick={() => props.uiStateActions.toggleModal("addData")}
                />
              </div>
            }
            interactionManager={
              <InteractionManager
                visStateActions={props.visStateActions}
                datasets={props.datasets}
                interactionConfig={props.interactionConfig}
              />
            }
            mapManager={
              <MapManager
                mapStyle={props.mapStyle}
                mapStyleActions={props.mapStyleActions}
                showAddMapStyleModal={() => {}}
              />
            }
          ></Sidepanel>
        </SideBar>
      </StyledSideBarContainer>
    );
  };
  return CustomSidebar;
}

export default CustomSidebarFactory;
