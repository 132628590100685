import React from "react";
import Header from "../Header";
import DatasetTable from "../DatasetTable";
import { useState } from "react";
import Actions from "../Actions";

const DataExplorer = () => {
  return (
    <div>
      <Header
        datasets={[]}
        choseDataset={""}
        setChoseDataset={""}
        dataset={{}}
        filters={[]}
        filteredDatasetLength={0}
      />
      <Actions isExportDataDisable={true} />
      <DatasetTable dataSource={[]} columns={[]} />
    </div>
  );
};

export default DataExplorer;
