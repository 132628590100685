import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import "antd/dist/antd.css";
import "./index.css";
import "./globalStyles/index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import "./styles/styles.css";
import { EnvProvider } from "./context/env.context";
import { SessionProvider } from "./context/session";
import { Auth0ProviderWithHistory } from "./auth0-provider-with-history";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./store";

ReactDOM.render(
  <EnvProvider>
    <Auth0ProviderWithHistory>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SessionProvider>
            <App />
          </SessionProvider>
        </PersistGate>
      </ReduxProvider>
    </Auth0ProviderWithHistory>
  </EnvProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
