import axios from "axios";
import globalConfig from "../utils/config";

export const updateEnvironment = (data) => {
  return axios
    .put(
      globalConfig.demandIntelService +
        globalConfig.apiRoutes.addEnv +
        "/" +
        data.envId,

      data.newEnv,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    )
    .then((res) => res.data);
};

export const getEnvList = (token) => {
  return axios
    .get(
      globalConfig.demandIntelService + globalConfig.apiRoutes.getEnvList,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data);
};
