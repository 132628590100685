import React, { useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { message } from "antd";
import { Dropdown, Menu, Modal } from "antd";
import StyledButton from "../Buttons/StyledAntdButton";
import styled from "styled-components";
import KeplerGlSchema from "kepler.gl/schemas";
import axios from "axios";
import { useSelector } from "react-redux";
import globalConfig from "../../utils/config";
import { useEnv } from "../../context/env.context";
import { useSession } from "../../context/session";
import GlobalLoading from "../GlobalLoading";
import useGuards from "../../hooks/useGuards";
import { views } from "../../constants/env";

const SaveButton = styled(StyledButton)`
  &:focus,
  &:hover {
    color: #fff;
    background-color: #1a60b4;
  }
`;

const SaveProject = () => {
  const map = useSelector((state) => state.keplerGl.map);
  const currentEnv = useSelector((state) => state.currentEnv);
  const { demandIntelService } = useEnv();
  const {
    getBearerToken,
    projectView,
    setProjectView,
    projectViewConfig,
    setProjectViewConfig,
  } = useSession();
  const { hasPermission } = useGuards();
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [confirmModalDetails, setConfirmModalDetails] = useState({});

  const canUpdateMyProject = hasPermission("di:MyProject:update");
  const canUpdateTeamProject = hasPermission("di:TeamProject:update");

  // This method is used as reference to show how to export the current kepler.gl instance configuration
  // Once exported the configuration can be imported using parseSavedConfig or load method from KeplerGlSchema
  const getMapConfig = () => {
    // create the config object
    return KeplerGlSchema.getConfigToSave(map);
  };

  const sanitizeMapConfig = (mapConfig) => {
    mapConfig.config.visState.filters = mapConfig.config.visState.filters.map(
      (filter) => {
        if (filter.name.length < 1) return false;
        return filter;
      }
    ).filter((filter) => filter !== false);

    return mapConfig;
  };

  const saveView = async () => {
    const saveToViewId = confirmModalDetails.key;
    const datasets = Object.keys(map?.visState.datasets);
    const mapConfig = getMapConfig();
    const payload = {
      datasetIds: datasets,
      datasets: datasets,
      metadata: sanitizeMapConfig(mapConfig),
    };
    const url = `${demandIntelService}${globalConfig.apiRoutes.postProjectView(
      currentEnv,
      saveToViewId
    )}`;

    if (projectViewConfig.version !== undefined) {
      payload.version = projectViewConfig.version;
      if (saveToViewId === views.MY_VIEW) {
        payload.majorVersion = projectViewConfig.majorVersion;
      }
    }
    // return "";

    // api call
    try {
      const request = {
        url: url,
        method: "POST",
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      };

      const response = await axios(request);

      // don't refresh view config if what got saved isn't the current view
      if (saveToViewId === projectView) {
        setProjectViewConfig(
          {
            projectId: currentEnv,
            viewId: saveToViewId,
            majorVersion: response.data.data.majorVersion,
            minorVersion: response.data.data.minorVersion,
            version: response.data.data.version,
          },
          true
        );
      }
    } catch (e) {
      console.error(e);
      message.error(
        `Error saving this view as "${confirmModalDetails.type}". Please try again`
      );
    }
  };

  const replaceMyViewWithTeamView = async () => {
    const url = `${demandIntelService}${globalConfig.apiRoutes.postReplaceMyView(
      currentEnv
    )}`;

    // api call
    try {
      const request = {
        url: url,
        method: "POST",
        data: {},
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      };

      await axios(request);
    } catch (e) {
      console.error(e);
      message.error('Error saving "team view" as "my view". Please try again');
    }
  };

  const doSaveAction = async () => {
    setIsLoading(true);

    try {
      if (confirmModalDetails.key === views.TEAM_VIEW_AS_MY_VIEW) {
        await replaceMyViewWithTeamView();

        // clear current project view so that the default happens
        // default assumes "my view" and loads that
        setProjectView(null);
      } else {
        await saveView();
      }
    } catch (e) {}

    setIsLoading(false);
    setConfirmModalDetails((confirmModalDetails) => ({ ...{} }));
  };

  const handleSaveAction = (e) => {
    const newModalDetails = { key: e.key, visible: true };
    switch (e.key) {
      case views.MY_VIEW:
        newModalDetails.title = "Save for me";
        newModalDetails.type = "my view";
        newModalDetails.body = [
          'The changes in this view will be saved for you only and can be accessed by selecting "my view" in the top left.',
        ];
        break;
      case views.TEAM_VIEW:
        newModalDetails.title = "Save for team";
        newModalDetails.type = "team view";
        newModalDetails.body = [
          'The changes in this view will be saved for everyone in this Project and can be seen by selecting "team view" in the top left.',
          "This will not affect the personal view of your team members.",
        ];
        break;
      case views.TEAM_VIEW_AS_MY_VIEW:
        newModalDetails.title = "Save team view as my view";
        newModalDetails.body = [
          'The current "team view" will overwrite "my view". Any personal changes will be overwritten. This action cannot be undone.',
        ];
        break;
      default:
        throw "Unknown save action: " + e.key;
    }

    setConfirmModalDetails((confirmModalDetails) => ({ ...newModalDetails }));
  };

  const buildMenuItems = () => {
    const menuItems = [];

    if (canUpdateMyProject) {
      menuItems.push({
        label: "Save for me",
        key: views.MY_VIEW,
      });
    }

    if (canUpdateTeamProject) {
      menuItems.push({
        label: "Save for team",
        key: views.TEAM_VIEW,
      });
    }

    /* DISABLED FUNCTIONALITY
     * will remove eventually
    if (canUpdateMyProject) {
      menuItems.push(
        {
          type: "divider",
          key: "divider",
        },
        {
          label: "Save team view as my view",
          key: views.TEAM_VIEW_AS_MY_VIEW,
        }
      );
    }
    */

    return menuItems;
  };

  if (!(canUpdateMyProject || canUpdateTeamProject)) {
    return <></>;
  }

  return (
    <>
      <Dropdown
        trigger={["click"]}
        visible={isDropdownVisible}
        overlay={
          <Menu
            onClick={handleSaveAction}
            selectable={false}
            items={buildMenuItems()}
          />
        }
        style={{
          width: 120,
        }}
      >
        <SaveButton
          type={"primary"}
          onClick={(e) => {
            const newState = !isDropdownVisible;
            if (newState) {
              setIsDropdownVisible(newState);
            } else {
              e.target.blur();
            }
          }}
          onBlur={(e) => {
            setIsDropdownVisible(false);
            e.relatedTarget?.click();
          }}
        >
          Save
          <DownOutlined />
        </SaveButton>
      </Dropdown>

      <Modal
        title={`Confirm changes: ${confirmModalDetails.title}`}
        visible={
          confirmModalDetails.visible !== undefined
            ? confirmModalDetails.visible
            : false
        }
        onOk={doSaveAction}
        onCancel={() => {
          setConfirmModalDetails((confirmModalDetails) => ({ ...{} }));
        }}
      >
        {confirmModalDetails.body?.map((line, idx) => {
          return <p key={`line-${idx}`}>{line}</p>;
        })}
      </Modal>

      {isLoading && <GlobalLoading />}
    </>
  );
};

export default SaveProject;
