import { addDataToMap } from "kepler.gl/actions";
import { message } from "antd";
import { useDispatch } from "react-redux";

import { processDataForKepler } from "../utils/processors";

function useAddDatasetToMap() {
  const dispatch = useDispatch();

  const addDatasetToMap = (fileType, datasetFile, datasetId, datasetName) => {
    const dataItem = processDataForKepler({
      fileType: fileType,
      dataset: datasetFile,
      datasetName: datasetName,
    });
    if (dataItem === false) return;

    const dataset = {
      data: dataItem,
      info: {
        id: datasetId,
        label: datasetName,
      },
    };

    try {
      dispatch(
        addDataToMap({
          datasets: dataset,
          options: {
            autoCreateLayers: false,
            centerMap: false,
          },
        })
      );
    }
    catch (e) {
      console.error("Error adding Datasets to Map:", fileType, datasetId, datasetName);
      message.error(`Error adding Datasets to Map: ${datasetName}`);
      return;
    }
  };
  return addDatasetToMap;
}

export default useAddDatasetToMap;
