import { useRef, useState } from "react";
import { addFilter, setFilter } from "kepler.gl/actions";
import { Popover } from "antd";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import AddButton from "../../components/Buttons/AddButton";
import Text from "../../components/Text";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import Filters from "./Filters";
import SelectDataset from "./SelectDataset";
import SelectField from "./SelectField";
import DatasetDetails from "./Actions/DatasetDetails";
import ExportData from "./Actions/ExportData";

const SectionOne = styled.div`
  padding: 24px 0;
  background-color: #ffffff;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e4e8;
`;
const SectionTwo = styled.div`
  padding: 24px 0 40px 0;
  display: flex;
  justify-content: space-between;
`;
const ChooseDataset = styled.div`
  padding-right: 64px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const FilterData = styled.div`
  display: flex;
  align-items: start;
`;
const FilterDataControls = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-right: 0.5rem;
`;
const DataSetFieldWrapper = styled.div`
  position: absolute;
  width: 300px;
  overflow-y: auto;
  z-index: 2;
  height: 360px;
`;

const AddFilterButton = styled(AddButton)`
  background-color: #DBEAF9;
  color: #154580;
  fill: #154580;
  border: none;
`;

const ExportDataSection = styled.div`
  margin-left: 2rem;
`;

const Header = (props) => {
  const dispatch = useDispatch();

  const {
    datasets,
    selectedDatasetId,
    setSelectedDatasetId,
    dataset,
    filters,
    filteredDatasetLength,
    isExportDataDisable,
  } = props;
  const fields = dataset?.fields;

  const [selectedField, setSelectedField] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  const onFieldSelect = (id) => {
    const field = dataset.fields.find((item) => item.id === id);
    dispatch(setFilter(filters.length - 1, "name", field.name));
    setSelectedField(field);
  };

  const onAddFilterClick = () => {
    dispatch(addFilter(selectedDatasetId));
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <SectionOne>
        <ChooseDataset>
          <Text className={"text28"}>Dataset:</Text>
          <div>
            <SelectDataset
              datasets={datasets}
              selectedDatasetId={selectedDatasetId}
              setSelectedDatasetId={setSelectedDatasetId}
            />
          </div>
          <div>
            <Text className={"text8"}>{filteredDatasetLength} rows</Text>
          </div>
        </ChooseDataset>
        <DatasetDetails
          activeDatasetId={selectedDatasetId}
          datasets={datasets}
        />
      </SectionOne>
      <SectionTwo>
        <FilterData>
          <FilterDataControls>
            <Text className={"text28"}>Filter:</Text>
            <Popover
              content={
                <SelectField
                  fields={fields}
                  selectedField={selectedField}
                  onFieldSelect={onFieldSelect}
                  filters={filters}
                  closePopup={closePopup}
                />
              }
              trigger="click"
              placement="bottom"
              overlayInnerStyle={{ width: 280 }}
              overlayClassName={"filter-modal"}
              destroyTooltipOnHide={true}
              visible={isOpen}
              onVisibleChange={(e) => {
                setIsOpen(e);
              }}
            >
              <>
                <AddFilterButton
                  onClick={onAddFilterClick}
                  disable={!dataset}
                  type="filter"
                >
                  Add filter
                </AddFilterButton>
            </>
            </Popover>
          </FilterDataControls>

          <Filters filters={filters} selectedDatasetId={selectedDatasetId} />
          <div style={{ position: "relative" }}>
            <DataSetFieldWrapper></DataSetFieldWrapper>
          </div>
        </FilterData>
        <ExportDataSection>
          <ExportData disable={isExportDataDisable} />
        </ExportDataSection>
      </SectionTwo>
    </div>
  );
};

export default Header;
