import React, { useState, useEffect } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import useEnvs from "../../hooks/calls/useEnvs";
import paths from "../../constants/paths";
import { setCurrentEnv } from "../../store/actions";
import Environment from "./Environment";
import { useSession } from "../../context/session";
import { views } from "../../constants/env";
import { useDatasetList } from "../../hooks/calls/useDatasetList";
import { redirectIfAnyCartoProject } from "../../utils/redirect";

const EnvironmentsManagement = () => {
  const location = useLocation();
  const currentEnv = useSelector((state) => state.currentEnv);
  const datasetList = useDatasetList(null);
  const { projectView, setProjectView } = useSession();
  const dispatch = useDispatch();

  const { data: envList } = useEnvs();
  const isYourEnvironments = location.pathname === paths.yourEnvironments;

  useEffect(() => {
    if (!envList) return;

    redirectIfAnyCartoProject(envList);

    // if we are in env page we don't set a default env
    if (isYourEnvironments) return;

    let envId = "";
    const envItem = envList.find((env) => env.id === currentEnv);
    if (!envItem) {
      envId = envList[0].id;
    } else {
      envId = envItem.id;
    }
    dispatch(setCurrentEnv(envId));

    // when changing envs, we always default to "MyView"
    setProjectView(views.MY_VIEW);
  }, [envList]);

  if (isYourEnvironments) return null;

  return (
    <div>
      {currentEnv && <Environment
        key={currentEnv}
        currentEnvId={currentEnv}
        currentProjectView={projectView}
      />}
    </div>
  );
};

export default withAuthenticationRequired(EnvironmentsManagement);
