import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { useEnv } from "../../context/env.context";
import { useQuery } from "react-query";
import globalConfig from "../../utils/config";

const useEnvs = (onStart = () => {}, onFinish = () => {}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { demandIntelService } = useEnv();

  const makeRequest = async (options) => {
    try {
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();

        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      onStart();
      const response = await axios(options.config);
      onFinish();
      const { data } = response;

      return data;
    } catch (error) {
      onFinish();
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return error.message;
    }
  };

  const getDatabaseLists = async () => {
    const config = {
      url: `${demandIntelService}${globalConfig.apiRoutes.getEnvList}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    };

    const response = await makeRequest({ config, authenticated: true });
    return response.data;
  };

  const { isLoading, data, isError, error, isFetched } = useQuery(
    "envList",
    () => {
      async function callApi() {
        return await getDatabaseLists();
      }

      return callApi();
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 0,
    }
  );

  return {
    isLoading,
    data,
    error,
    isError,
    isFetched,
  };
};
export default useEnvs;
