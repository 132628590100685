import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as DownLoadIcon } from "../../assets/img/download.svg";
import { ReactComponent as UploadIcon } from "../../assets/img/upload.svg";
import { ReactComponent as LogoutIcon } from "../../assets/img/logout.svg";
import { ReactComponent as FilterIcon } from "../../assets/img/filter.svg";
import Plus from "../icons/Plus";
import Secure from "../icons/Secure";
import { InfoCircleOutlined } from "@ant-design/icons";

const Button = styled.button`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d1d5da;
  border-radius: 2px;
  padding: 4px 16px;
  gap: 8px;
  color: #24292e;
  fill: #24292e;
  font-size: 1em;
  white-space: nowrap;
  font-family: inter;
  line-height: 22px;
  cursor: pointer;
  ${(props) =>
    props.type === "secure" &&
    css`
      border-color: #2579dd;
      color: #2579dd;
    `}
  ${(props) =>
    props.type === "info" &&
    css`
      color: #2579dd;
    `}
  ${(props) =>
    !props.disable &&
    css`
      :hover {
        border-color: #4a93e4;
        color: #4a93e4;
        fill: #4a93e4;
        svg {
          fill: #4a93e4;
        }
      }
    `}
      ${(props) =>
    props.type === "logout" &&
    css`
      padding: 9px;
      :hover {
        border-color: #d1d5da;
        svg {
          fill: red;
        }
      }
    `}
`;
const AddButton = (props) => {
  const { children, onClick, className, type = "add", disable } = props;
  const icon = () => {
    if (type === "add") return <Plus />;
    if (type === "download") return <DownLoadIcon />;
    if (type === "secure") return <Secure />;
    if (type === "upload") return <UploadIcon />;
    if (type === "logout") return <LogoutIcon />;
    if (type === "info") return <InfoCircleOutlined />;
    if (type === "filter") return <FilterIcon />;
    return null;
  };
  const onButtonClick = () => {
    if (!disable) {
      onClick();
    }
  };
  return (
    <Button
      onClick={onButtonClick}
      className={className}
      disable={disable}
      type={type}
    >
      {icon()}
      {children}
    </Button>
  );
};

export default AddButton;
