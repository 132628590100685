import { Table } from "antd";
import React from "react";
import styled from "styled-components";
const BorderedTable = styled(Table)`
  .ant-table {
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
  }
`;
const DatasetTable = (props) => {
  const { dataSource, columns } = props;

  return (
    <div>
      <BorderedTable
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: "max-content" }}
        // scroll={{ x: "auto" }}
        size={"small"}
      />
    </div>
  );
};

export default DatasetTable;
