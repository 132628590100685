import React, { useEffect, useState } from "react";
import { useQueries } from "react-query";
import { useDispatch } from "react-redux";
import { addDataToMap } from "kepler.gl/actions";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { message } from "antd";

import GlobalLoading from "../GlobalLoading";
import { apolloClient } from "../../ApolloWrapper";
import { useSession } from "../../context/session";
import paths from "../../constants/paths";
import {
  assembleDiscoveryDataset,
  includeLoadableDiscoveryAssets,
  isDiscoveryFullSearchResult,
} from "../../utils/discovery";
import { appendToKeplerDataset, processDataForKepler } from "../../utils/processors";
import { parseUri, parseStreamedGeoJsonResponse } from "../../utils/tools";

const All = (props) => {
  const { config, dsRefs } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const { getBearerToken } = useSession();
  const [ isDatasetLoadingDone, setIsDatasetLoadingDone ] = useState(false);

  const queries = useQueries(
    dsRefs.map((ref) => {
      if (ref.request) {
        return {
          queryKey: ["discovery", ref.id],
          queryFn: () => fetch(ref.request),
        };
      }
      else {
        return {
          queryKey: ["dataset", ref.id, ref.generationId],
          queryFn: () => fetch(ref.url),
        };
      }
    })
  );
  const isInitialLoadingDone = queries.every(
    (q) => q.isLoading === false && q.isFetching === false
  );

  useEffect(() => {
    if (!isInitialLoadingDone) return;

    const loadDatasets = async () => {
      const bearerToken = await getBearerToken();
      const latestDatasets = (await Promise.all(
        queries
          .filter((query) => query.status === "success")
          .map(async (wrappedResponse, index) => {
            //console.log("response", response);
            const response = wrappedResponse.data;
            const ref = dsRefs[index];
            const info = {
              id: ref.id,
              label: ref.name,
            };

            let rawData, loadedData;

            if (ref.request) {
              rawData = await response.clone().json()
              const product = rawData.product;
              const release = product.releases[0];
              const results = assembleDiscoveryDataset(product, release);
              info.label = results.label;

              // minimal representation of Discovery Dataset
              loadedData = processDataForKepler({
                fileType: results.type,
                dataset: results.data,
                datasetName: results.label,
              });

              await includeLoadableDiscoveryAssets({
                keplerDataset: loadedData,
                response: rawData,
                label: results.label
              });
            }
            else {
              let fileType;
              const { file: filename } = parseUri(response.url);

              if (filename.endsWith('.geojson') || filename.endsWith('.json')) {
                rawData = await parseStreamedGeoJsonResponse(response.clone());
                if (rawData !== false) fileType = 'json';
              }

              if (fileType === undefined) {
                try {
                  rawData = await response.clone().json();
                  fileType = 'json';
                } catch (error) {
                  rawData = await response.clone().text();
                  fileType = 'unknown';
                }
              }

              loadedData = processDataForKepler({
                fileType: fileType,
                dataset: rawData,
                datasetName: ref.name,
              });
            }

            if (loadedData !== false) {
              return {
                data: loadedData,
                info: info,
              };
            }
          })
      )).filter((dataset) => !!dataset);

      try {
        await dispatch(
          addDataToMap({
            datasets: latestDatasets,
            options: {
              autoCreateLayers: false,
              centerMap: false,
            },
          })
        );
      }
      catch (e) {
        const labels = latestDatasets.map((dataset) => dataset.info.label);
        message.error(`Error adding Datasets to Map: ${labels.join(", \n")}`);
      }
      /*
      for (let i = 0; i < latestDatasets.length; i++) {
        const dataset = latestDatasets[i];
        try {
          await dispatch(
            addDataToMap({
              datasets: dataset,
              options: {
                autoCreateLayers: false,
                centerMap: false
              },
            })
          );
        }
        catch (e) {
          console.error("Error adding Dataset to Map:", dataset);
          message.error(`Error adding Dataset to Map: ${dataset.info.label}`);
        }
      }
      */

      setIsDatasetLoadingDone(true);
    }

    loadDatasets();
  }, [config, isInitialLoadingDone]);

  const showLoading = (
    [
      paths.visualization,
      paths.data,
    ].indexOf(location.pathname) !== -1 &&
    (!isInitialLoadingDone || !isDatasetLoadingDone)
  );

  return showLoading ? <GlobalLoading /> : null;
};

export default All;
