import { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

import { useEnv } from "./env.context";
import { views } from "../constants/env";

export const SessionContext = createContext({});

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error(`useSession must be used within a SessionProvider`);
  }
  return context;
};

export const SessionProvider = ({children}) => {
  const empty = "";
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { demandIntelService } = useEnv();
  const currentEnv = useSelector((state) => state.currentEnv);
  const [bearerToken, setBearerToken] = useState(empty);
  const [accessToken, setAccessToken] = useState({});
  const [projectView, setProjectView] = useState(views.MY_VIEW);
  const [projectViewConfig, _setProjectViewConfig] = useState({});

  const setProjectViewConfig = (cfg, merge) => {
    if (cfg.viewId !== undefined) {
      if (cfg.projectId !== currentEnv) {
        throw `Project of View config does not match Current Project: ${cfg.projectId} != ${currentEnv}`;
      }
      else if (cfg.viewId !== projectView) {
        throw `View of View config does not match Current View: ${cfg.viewId} != ${projectView}`;
      }

      if (merge === true) {
        _setProjectViewConfig((projectViewConfig) => ({...projectViewConfig, ...cfg}));
      }
      else {
        _setProjectViewConfig((projectViewConfig) => ({...cfg}));
      }
    }
    else {
      _setProjectViewConfig((projectViewConfig) => ({...{}}));
    }
  }

  const getBearerToken = async () => {
    const token = isAuthenticated ? await getAccessTokenSilently() : empty;

    if (token !== empty) {
      if (token === bearerToken)
        return token;

      setBearerToken(token);

      const decodedToken = jwt_decode(token);

      //console.log('Updating bearer token: ' + token);
      //console.log('Updating access token: ' + JSON.stringify(decodedToken));

      setAccessToken(accessToken => ({...decodedToken}));

      return token;
    }
  }

  return (
    <SessionContext.Provider value={{
      isAuthenticated,
      bearerToken,
      accessToken,
      getBearerToken,
      projectView,
      setProjectView,
      projectViewConfig,
      setProjectViewConfig,
    }}>
      {children}
    </SessionContext.Provider>
  );
}
