import React, { useState } from "react";
import DatasetDetailDrawer from "../../../../../components/ui/organisms/DatasetDetailDrawer/DatasetDetailDrawer";

const Overview = (props) => {
  const { visible, closeDrawer, dataset, returnToDatasetListView } = props;
  const [selectedDataset, setSelectedDataset] = useState({});

  return (
    <div>
      <DatasetDetailDrawer
        data={dataset}
        visibility={visible}
        handleOnClose={closeDrawer}
        returnToDatasetListView={returnToDatasetListView}
      />
    </div>
  );
};

export default Overview;
