import axios from "axios";
import { useEnv } from "../../context/env.context";
import { useQuery } from "react-query";
import globalConfig from "../../utils/config";
import { useSession } from "../../context/session";

export const useDatasetList = (currentEnv) => {
  const { getBearerToken } = useSession();
  const { demandIntelService } = useEnv();

  const makeRequest = async (options) => {
    try {
      const response = await axios(options.config);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return error.message;
    }
  };

  const getDatasetList = async () => {
    let url = `${demandIntelService}${globalConfig.apiRoutes.datasetList}`
    url = currentEnv !== null ? `${url}?envId=${currentEnv}` : url;
    const config = {
      url: url,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `bearer ${await getBearerToken()}`,
      },
    };
    const response = await makeRequest({ config, authenticated: true });
    return response.data;
  };

  const { isLoading, data, isError, error, isFetched } = useQuery(
    ["datasetList", currentEnv],
    () => {
      async function callApi() {
        return await getDatasetList();
      }

      return callApi();
    }
  );

  return {
    isLoading,
    data,
    error,
    isError,
    isFetched,
    getDatasetList,
  };
};
