import React from "react";

const Upload = (props) => {
  const { type } = props;
  const fill = "#2579DD";
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 22V28H4V22H0V28C0 30.2 1.8 32 4 32H28C30.2 32 32 30.2 32 28V22H28ZM6 10L8.82 12.82L14 7.66V24H18V7.66L23.18 12.82L26 10L16 0L6 10Z"
        fill={fill}
      />
    </svg>
  );
};

export default Upload;
