import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useSession } from "./context/session";
import config from "./utils/config";

export let apolloClient;

export const ApolloWrapper = ({ children }) => {
  const { getBearerToken } = useSession();

  const httpLink = new HttpLink({
    uri: config.graphQlRoot,
  });

  const authLink = setContext(async (request, { headers, ...rest }) => {
    const bearerToken = await getBearerToken();
    if (!bearerToken) return { headers, ...rest };

    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `bearer ${bearerToken}`,
      },
    };
  });

  apolloClient = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
