import React from "react";
import styled from "styled-components";
import Text from "../Text";
const Wrapper = styled.div`
  border-radius: 2px;
  background: #f6f8fa;
  padding: 16px;
  margin-bottom: 22px;
`;

const UploadDescription = () => {
  return (
    <Wrapper>
      <Text className={"text17 mb-4"}>HOW IT WORKS</Text>
      <Text className={"text16 mb-16"}>When you upload a data file:</Text>
      <ol className={"p-0-0-0-16"}>
        <li>
          <Text className={"text16 mb-16"}>
            We validate the compatiblity of the data uploaded and process it for
            analysis
          </Text>
        </li>
        <li>
          <Text className={"text16"}>
            Once the data is processed, our system (and our Applied Data Science
            team) is able to make use of this information to improve predictions
            for your organization
          </Text>
        </li>
      </ol>
    </Wrapper>
  );
};

export default UploadDescription;
