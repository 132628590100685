import React, { useState } from "react";
import AddButton from "../../../components/Buttons/AddButton";
import ExportDataModal from "./ExportDataModal";

const ExportData = (props) => {
  const { disable } = props;
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  return (
    <>
      <AddButton type={"download"} onClick={openModal} disable={disable}>
        Export Data
      </AddButton>

      {!disable && (
        <ExportDataModal
          setIsModalVisible={setIsOpen}
          isModalVisible={isOpen}
        />
      )}
    </>
  );
};

export default ExportData;
