import { PanelHeaderActionFactory } from "kepler.gl/components";
import styled from "styled-components";
import { ReactComponent as VisibleIcon } from "../assets/img/visibility.svg";
import { ReactComponent as NotVisibleIcon } from "../assets/img/visibility_off.svg";
import { ReactComponent as DeleteIcon } from "../assets/img/delete.svg";
import useGuards from "../hooks/useGuards";



const StyledIconWrapper = styled.div`
  .layer-panel__header & {
    display: flex;
    svg path {
        fill: #959DA5;
      }
    }
  }
  .layer-panel__header:hover & {
    svg path {
      fill: #24292E;
    }
  }
`;

const StyledVisibleIcon = (props) => {
  return (
    <StyledIconWrapper>
      <VisibleIcon {...props} />
    </StyledIconWrapper>
  );
}

const StyledDeleteIcon = styled(DeleteIcon)`
  width: 14px;
  height: 14px;
`;

const customPanelHeaderFactory = (...deps) => {
  const PanelHeader = PanelHeaderActionFactory(...deps);

  // return PanelHeader;

  const CustomPanelHeader = (props) => {
    const { hasPermission } = useGuards();
    const className = props.className && props.className.trim();
    const _props = {...props};

    if (className) {
      if (className.includes("layer__duplicate")) {
        return <></>;
      }

      if (className.includes("layer__visibility-toggle")) {
        _props.IconComponent = (
          props.tooltip === "tooltip.hideLayer" ?
            StyledVisibleIcon :
            NotVisibleIcon
        );
      }
      else if (className.includes("layer__remove-layer")) {
        _props.IconComponent = StyledDeleteIcon;
      }

      if (
        hasPermission("di:MyProject.Layer:update") || 
        hasPermission("di:TeamProject.Layer:update")
      ) {
        return <PanelHeader {..._props}></PanelHeader>;
      }
      else if (
        className.includes("layer__enable-config") ||
        className.includes("layer__remove-layer")
      ) {
        return null;
      } else {
        return <PanelHeader {..._props}></PanelHeader>;
      }
    }
    return (
      <PanelHeader {..._props}></PanelHeader>
    ); /* fix: filter-panel action */
  };
  return CustomPanelHeader;
};
customPanelHeaderFactory.deps = PanelHeaderActionFactory.deps;
export default customPanelHeaderFactory;
