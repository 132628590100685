import React from "react";
import styled from "styled-components";
import { ReactComponent as CrossIcon } from "../../assets/img/cross.svg";
import Text from "../../components/Text";
import { useDispatch } from "react-redux";
import { removeFilter } from "kepler.gl/actions";

const FilterList = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  flex-wrap: wrap;
`;

const Filter = styled.div`
  border-radius: 2px;
  padding: 4px 8px;
  background-color: #fafbfc;
  border: 1px solid #e1e4e8;
  display: flex;
  align-items: center;
  button {
    cursor: pointer;
  }
`;

const Filters = (props) => {
  const dispatch = useDispatch();

  const { filters, selectedDatasetId } = props;
  const onDeleteFilter = (filterIdx) => {
    dispatch(removeFilter(filterIdx));
  };
  const getFilterText = (filter) => {
    const fieldName = filter.name[0];
    if (fieldName && filter.type === "range") {
      return (
        <div className="flex">
          {filter.value[0]} &le; <Text className={"text28 mr-8 ml-8"}>{fieldName}</Text> &le; {filter.value[1]}
        </div>
      );
    }
    if (fieldName && filter.type === "timeRange") {
      const dateLeft = new Date(filter.value[0]);
      const dateRight = new Date(filter.value[1]);
      return (
        <div className="flex">
          {dateLeft.toDateString()} &le; <Text className={"text28 mr-8 ml-8"}>{fieldName}</Text> &le; {dateRight.toDateString()}
        </div>
      );
    }
    if (fieldName && filter.type === "multiSelect") {
      return (
        <div className="flex">
          <Text className={"text28 mr-8"}>{fieldName}</Text> is {filter.value.join(', ')}
        </div>
      );
    }
    return "";
  };
  return (
    <FilterList>
      {filters.map((filter, filterIdx) => {
        if (
          !filter?.dataId.includes(selectedDatasetId) ||
          filter?.value?.length === 0 ||
          (filter?.type !== "range" &&
            filter?.type !== "multiSelect" &&
            filter?.type !== "timeRange")
        )
          return null;
        return (
          <Filter key={filter.id}>
            <Text className={"text12 mr-12"}>{getFilterText(filter)}</Text>
            <CrossIcon
              className={"pointer"}
              onClick={() => onDeleteFilter(filterIdx)}
            />
          </Filter>
        );
      })}
    </FilterList>
  );
};

export default Filters;
