import React, { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { AUTH_TOKENS } from "../../constants/config";
import { messages } from "../../constants/l10n";
import theme from "./theme";
import {
  injectComponents,
  SidePanelFactory,
  PanelHeaderActionFactory,
  LayerPanelHeaderFactory,
  LoadDataModalFactory,
  LayerHoverInfoFactory,
  MapPopoverFactory,
} from "kepler.gl/components";
import customSidePanelFactory from "../../factories/custom-side-panel";
import customLayerPanelHeaderFactory from "../../factories/custom-layer-panel-header-factory";
import customPanelHeaderAction from "../../factories/custom-panel-header-action";
import customLoadDataModalFactory from "../../factories/custom-load-data-model";
import customLayerHoverInfoFactory from "../../factories/custom-layer-hover-info";
import customMapPopoverFactory from "../../factories/custom-map-popover-factory";

// Inject custom header into Kepler.gl,
const KeplerGl = injectComponents([
  [SidePanelFactory, customSidePanelFactory],
  [LayerPanelHeaderFactory, customLayerPanelHeaderFactory],
  [PanelHeaderActionFactory, customPanelHeaderAction],
  [LoadDataModalFactory, customLoadDataModalFactory],
  [MapPopoverFactory, customMapPopoverFactory],
  [LayerHoverInfoFactory, customLayerHoverInfoFactory],
]);

let isMounted = false;
const CustomKeplerGl = (props) => {
  const { headerHeight = 0, temp = false } = props;
  const [show, setShow] = useState(true);
  useEffect(() => {
    isMounted = true;
    if (temp)
      setTimeout(() => {
        setShow(false);
      }, 0);
  }, [temp]);

  if (!show) return null;
  if (temp && isMounted) return null;

  return (
    <AutoSizer>
      {({ height, width }) => (
        <KeplerGl
          id="map"
          theme={theme}
          mapboxApiAccessToken={AUTH_TOKENS.MAPBOX_TOKEN}
          width={width}
          height={height - headerHeight}
          mint={false}
          localeMessages={messages}
        />
      )}
    </AutoSizer>
  );
};

export default CustomKeplerGl;
