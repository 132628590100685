import { useEffect, useState } from "react";
import axios from "axios";
import config from "../../utils/config";
import Dataset from "./Dataset";
import { receiveMapConfig, updateMap } from "kepler.gl/actions";
import { useDispatch } from "react-redux";
import GlobalLoading from "../GlobalLoading";
import { message } from "antd";
import { useSession } from "../../context/session";
import { useProject } from "../../hooks/useProject";

const Environment = (props) => {
  const { currentEnvId, currentProjectView } = props;
  const { setProjectViewConfig } = useSession();
  const { loadProjectViewConfig } = useProject();
  const [isEnvLoading, setIsEnvLoading] = useState(false);
  const [readyConfig, setReadyConfig] = useState(null);
  const [datasetConfig, setDatasetConfig] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const loadViewConfig = async (envId, viewId) => {
      setIsEnvLoading(true);
      try {
        const cfg = await loadProjectViewConfig(envId, viewId);
        if (cfg.error === undefined) {
          setProjectViewConfig(cfg.config);
          dispatch(receiveMapConfig(cfg.parsedConfig));

          if (
            cfg.parsedConfig.mapState?.latitude !== undefined &&
            cfg.parsedConfig.mapState?.longitude !== undefined
          ) {
            dispatch(updateMap(cfg.parsedConfig.mapState));
          }

          setDatasetConfig((datasetConfig) => ({
            ...{
              version: `${cfg.config.majorVersion}.${cfg.config.minorVersion}.${cfg.config.version}`,
              parsedConfig: cfg.parsedConfig,
              datasets: cfg.config.datasets,
            },
          }));
        } else {
          message.error(cfg.error);
        }
      } catch (e) {
        console.error(e);
      }
      setIsEnvLoading(false);
    };

    loadViewConfig(currentEnvId, currentProjectView);
    //console.log("Loading view config", currentEnvId, currentProjectView);
  }, [currentProjectView]);

  return (
    <div>
      {datasetConfig.datasets?.length > 0 && (
        <Dataset config={datasetConfig} currentEnvId={currentEnvId} key={datasetConfig.version} />
      )}

      {isEnvLoading && <GlobalLoading />}
    </div>
  );
};

export default Environment;
