import { useDispatch } from "react-redux";
import { addDataToMap, loadFiles } from "kepler.gl/actions";
import Processors from "kepler.gl/processors";

import { parseUri, parseStreamedGeoJsonResponse } from "../utils/tools";

/**
 * Load a file from a remote URL
 * @param url
 * @returns {Promise<any>}
 */
/*
function loadRemoteRawData(url) {
  if (!url) {
    // TODO: we should return reject with an appropriate error
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    request(url, (error, result) => {
      if (error) {
        reject(error);
        return;
      }
      const responseError = detectResponseError(result);
      if (responseError) {
        reject(responseError);
        return;
      }
      resolve([result.response, url]);
    });
  });
}
*/

const useLoadMap = () => {
  const dispatch = useDispatch();

  /* NOT USED
  const loadDataset = (props) => {
    const { dataUrl, onFinish = () => {}, onStart = () => {} } = props;
    onStart();
    fetch(dataUrl).then(async (response) => {
      if (response.ok) {
        return await (await response.blob()).text();
      }

      onFinish();
      // TODO
    })
    .then((data) => {
      const { file: filename } = parseUri(dataUrl);
      // const jsonRes = JSON.parse(file);
      // const data = Processors.processKeplerglJSON(jsonRes);
      // dispatch(addDataToMap(data));

      dispatch(loadFiles([new File([data], filename)])).then(() => {});
      onFinish();
    });
  };
  */

  const getFile = async (props) => {
    const {
      dataUrl,
      onFinish = () => {},
      onStart = () => {},
      callback = () => {},
    } = props;

    onStart();

    const response = await fetch(dataUrl);
    if (!response.ok) {
      onFinish();
      // TODO
      return;
    }

    const { file: filename } = parseUri(dataUrl);
    let file, fileType;

    if (filename.endsWith('.geojson') || filename.endsWith('.json')) {
      file = await parseStreamedGeoJsonResponse(response.clone());
      if (file !== false) fileType = 'json';
    }

    if (fileType === undefined) {
      try {
        file = await response.clone().json();
        fileType = 'json';
      } catch (error) {
        try {
          file = await response.clone().text();
          fileType = 'unknown';
        }
        catch (error) {
          onFinish();
          // TODO
          return;
        }
      }
    }

    callback(file, fileType);
    onFinish();
  };

  return { getFile };
};

export default useLoadMap;
