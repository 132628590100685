import { gql } from "@apollo/client";
const GET_Discovery = gql`
  query search($input: SearchInput) {
    search(input: $input) {
      more
      nextLimit
      nextOffset
      results {
        ... on FullSearchResult {
          product {
            id
            internalName
            displayName
            description
            license
            reference
            dataSteward
            tags {
              name
              value
            }
            releases {
              id
              publishStatus
              name
              description
              version
              uris
              extent
              license
              instanceIds
              tags {
                name
                value
              }
              createDate
              modifyDate
            }
            createDate
            modifyDate
          }
          instances {
            id
            assets {
              id
              uris
              type
              metadata
            }
          }
        }
      }
    }
  }
`;

const GET_Signed_Urls_Of_Discovery = gql`
  query GetLinks($input: String!) {
    download(reference: $input)
  }
`;

export { GET_Discovery, GET_Signed_Urls_Of_Discovery };
