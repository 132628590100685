import { LayerHoverInfoFactory } from "kepler.gl/components";
import { ignoredColumns } from "../../utils/constants.js";
import { LayerHoverInfoWrapper } from "./style";

const CustomPanelHeaderFactory = (...deps) => {
  const Component = LayerHoverInfoFactory(...deps);

  const CustomPanelHeader = (props) => {
    const hoverFields = props.fields
      .filter((field) => !(
        ignoredColumns.includes(field.name.toLowerCase())
        || field.analyzerType?.toLowerCase() === "geometry"
      ))
      .map((field) => ({ name: field.name, format: null }));

    const showAll = props.frozen;
    return (
      <LayerHoverInfoWrapper>
        {showAll ? (
          <Component {...props} fieldsToShow={hoverFields}></Component>
        ) : (
            <Component {...props}></Component>
          )}
      </LayerHoverInfoWrapper>
    );
  };
  return CustomPanelHeader;
};
CustomPanelHeaderFactory.deps = LayerHoverInfoFactory.deps;
export default CustomPanelHeaderFactory;
