import IFrame from "../IFrame/IFrame";

const Dashboard = () => {
  return (
    <IFrame
      srcKey="dashboardLink"
    />
  );
};

export default Dashboard;
