import { LayerPanelHeaderFactory } from "kepler.gl/components";
import useGuards from "../../hooks/useGuards";

const CustomPanelHeaderFactory = (...deps) => {
  const PanelHeader = LayerPanelHeaderFactory(...deps);

  const CustomPanelHeader = (props) => {
    const { hasPermission } = useGuards();

    const onToggleEnableConfig = (e) => {
      if (
        hasPermission("di:MyProject.Layer:update") ||
        hasPermission("di:TeamProject.Layer:update")
      ) {
        props.onToggleEnableConfig(e);
      }
      return false;
    };
    return (
      <PanelHeader
        {...props}
        onToggleEnableConfig={onToggleEnableConfig}
      ></PanelHeader>
    );
  };
  return CustomPanelHeader;
};
CustomPanelHeaderFactory.deps = LayerPanelHeaderFactory.deps;
export default CustomPanelHeaderFactory;
