import React from "react";
import { useSelector } from "react-redux";
import Navbar from "../../components/Header/Header";
import styled from "styled-components";
import ShowData from "./ShowData";
import EmptyData from "./EmptyData";
const Content = styled.div`
  padding: 2.5rem 2rem;
`;

const DataExplorer = () => {
  const map = useSelector((state) => state.keplerGl.map);
  return (
    <div>
      <Navbar />
      <Content>
        {map ? (
          <ShowData map={map} />
        ) : (
          <div>
            <EmptyData />
          </div>
        )}
      </Content>
    </div>
  );
};

export default DataExplorer;
