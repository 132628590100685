import React, { useState } from "react";
import { Drawer } from "antd";
import styled from "styled-components";
import { ReactComponent as CrossIcon } from "../../../../assets/img/cross.svg";
import DatasetOverview from "./DatasetOverview";
import DatasetDetailsList from "./DatasetDetailsList";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DetailButton from "./DetailButton";

const StyledDrawer = styled(Drawer)`
  /* .ant-drawer-content-wrapper {
    width: 50% !important;
  } */
  .ant-drawer-body {
    padding: 0;
  }
`;
const DrawerHeader = styled.div`
  padding: 1.5rem;
  background: #f6f8fa;
`;

const DatasetDetailsListDrawerBody = styled.div`
  padding: 24px 0 24px 0;
`;

const DatasetDetails = (props) => {
  const {activeDatasetId, datasets} = props;
  const [visible, setVisible] = useState(false);
  const [selectedDatasetIndex, setSelectedDatasetIndex] = useState(null);

  const returnToDatasetListView = () => {
    setSelectedDatasetIndex(null);
  };
  const showDrawer = () => {
    setVisible(true);
  };
  const hideDrawer = () => {
    setVisible(false);
  };

  return (
    <div>
      <DetailButton onClick={showDrawer} />
      <StyledDrawer
        placement={"right"}
        width={"45%"}
        onClose={hideDrawer}
        visible={visible}
        closable={false}
      >
        <DrawerHeader>
          {selectedDatasetIndex === null ? (
            <CrossIcon
              className="pointer"
              width={18}
              height={18}
              onClick={hideDrawer}
            />
          ) : (
            <ArrowLeftOutlined
              onClick={returnToDatasetListView}
              width={18}
              height={18}
            />
          )}
        </DrawerHeader>
        {selectedDatasetIndex === null ? (
          <DatasetDetailsListDrawerBody>
            <DatasetDetailsList
              datasets={datasets}
              activeDatasetId={activeDatasetId}
              setSelectedDatasetIndex={setSelectedDatasetIndex}
            />
          </DatasetDetailsListDrawerBody>
        ) : (
          <div className="p-24">
            <DatasetOverview
              visible={visible}
              closeDrawer={hideDrawer}
              dataset={datasets[selectedDatasetIndex]}
              returnToDatasetListView={returnToDatasetListView}
            />
          </div>
        )}
      </StyledDrawer>
    </div>
  );
};

export default DatasetDetails;
