import { Input } from "antd";
import React from "react";
const { Search } = Input;

const SearchInput = (props) => {
  const { value, onChange } = props;
  return (
    <Search
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      placeholder="Search by name"
      loading={false}
    />
  );
};

export default SearchInput;
