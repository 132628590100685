import IFrame from "../IFrame/IFrame";

const Documentation = () => {
  return (
    <IFrame
      srcKey="documentationLink"
    />
  );
};


export default Documentation;
