import React from "react";
import DiscoveryTable from "./DiscoveryTable";
import Empty from "./Empty";

const SearchResult = (props) => {
  const { data, onSetVersion } = props;
  return (
    <div>
      {
        data === undefined ?
          <Empty /> : (
          data.length > 0 ? (
            <DiscoveryTable data={data} onSetVersion={onSetVersion} />
          ) : (
            <Empty message="No results found"/>
          )
        )
      }
    </div>
  );
};

export default SearchResult;
