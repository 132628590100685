import React, { useState } from "react";

const FallbackImage = (props) => {
  const { src, fallback, width, height, alt, className } = props;
  const [imgLink, setImgLink] = useState(src);
  return (
    <img
      src={imgLink}
      alt={alt}
      width={width}
      height={height}
      className={className}
      onError={() => setImgLink(fallback)}
    />
  );
};

export default FallbackImage;
