import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import Text from "../../../../components/Text";

const DetailButton = (props) => {
  const { onClick } = props;
  return (
    <div onClick={onClick} className="flex-c-c pointer">
      <InfoCircleOutlined className="mr-8" />
      <Text className={"text16"}>Details</Text>
    </div>
  );
};

export default DetailButton;
