import { parse } from "json2csv";
import geojsonStream from "geojson-stream-whatwg";

export const getColorFromArray = (colorArray) => {
  return `rgb(${colorArray[0]}, ${colorArray[1]},${colorArray[2]})`;
};

export const getVisualizationType = (visibilityType) => {
  if (visibilityType === "GeoJsonLayer") return "Polygon";
  return visibilityType;
};

export const convertObjectToCSV = (obj) => {
  const fields = Object.keys(obj);
  const opts = { fields };
  const csv = parse(obj, opts);
  return csv;
};

export const randomString = (length) => {
  length = length !== undefined ? length : 8;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  const result = [];
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join('');
}

// DELETE THIS???
export const isDiscoveryDataset = (dataset) => {
  return (
    dataset.fields[0].name === "__fromDiscovery" ||
    dataset.fields[1].name === "__fromDiscovery"
  );
};

// DELETE THIS???
export const isGeojsonDiscovery = (dataset) => {
  return dataset.fields[0].name === "_geojson";
};

export const uniqueArray = (array, objKey = "id") => {
  return array.filter((item, index, self) => {
    return (
      index ===
      self.findIndex((t, index) => {
        return t[objKey] === item[objKey];
      })
    );
  });
};

export const decodeJson = (value) => {
  return (
    typeof value === "string" ?
      JSON.parse(value) :
      value
  )
}

export const getDataTypeColor = (dataType) => {
  dataType = dataType.toLowerCase().trim();
  switch (dataType) {
    case "integer":
    case "real":
      return "cyan";
    case "boolean":
      return "blue";
    case "timestamp":
      return "purple";
    case "string":
      return "orange";
    case "geojson":
      return "magenta";
    default:
      console.log('Unknown data type:', dataType);
      return "default";
  }
};

export const parseUri = (str) => {
  const o = parseUri.options;
  const m = o.parser[o.strictMode ? "strict" : "loose"].exec(str);
  const uri = {};
  let i = 14;

  while (i--) uri[o.key[i]] = m[i] || "";

  uri[o.q.name] = {};
  uri[o.key[12]].replace(o.q.parser, ($0, $1, $2) => {
    if ($1) uri[o.q.name][$1] = $2;
  });

  return uri;
};

parseUri.options = {
  strictMode: false,
  key: [
    "source",
    "protocol",
    "authority",
    "userInfo",
    "user",
    "password",
    "host",
    "port",
    "relative",
    "path",
    "directory",
    "file",
    "query",
    "anchor",
  ],
  q: {
    name: "queryKey",
    parser: /(?:^|&)([^&=]*)=?([^&]*)/g,
  },
  parser: {
    strict:
      /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
    loose:
      /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/,
  },
};

export const parseStreamedGeoJsonResponse = async (response) => {
  // eslint-disable-next-line no-undef
  const decoder = new TextDecoderStream();
  const readableStream = response.body
    .pipeThrough(decoder)
    .pipeThrough(geojsonStream.parse());
  const reader = readableStream.getReader();
  const featureCollection = {
    type: 'FeatureCollection',
    features: []
  };

  while (true) {
    const { done, value: feature } = await reader.read();
    if (done) break;
    featureCollection.features.push(feature);
  }

  if (featureCollection.features.length < 1) return false;
  return featureCollection;
}
