import React from "react";
import styled, { css } from "styled-components";
import Text from "../../components/Text";

const Box = styled.div`
  border: 1px solid #d1d5da;
  padding: 8px 18px;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;
  ${(props) => {
    if (props.isSelected) {
      return css`
        border-color: #1a60b4;
      `;
    }
  }}
`;

const SelectableBox = (props) => {
  const { title, rowNumbers, isSelected, onSelect, className } = props;
  return (
    <Box isSelected={isSelected} onClick={onSelect} className={className}>
      <Text className="text5">{title}</Text>
      <Text className="text14">{rowNumbers} rows</Text>
    </Box>
  );
};

export default SelectableBox;
