import * as actionTypes from "../actionTypes";

const envsReducer = (state = "", action) => {
  //console.log(action.type);
  switch (action.type) {
    case actionTypes.SET_CURRENT_ENV:
      return action.payload;
    default:
      return state;
  }
};

export { envsReducer };
