import { Select } from "antd";
import SelectIcon from "../icons/Select";
import Spinner from "../Loading/Spinner";
import GlobalLoading from "../GlobalLoading";
import useEnvs from "../../hooks/calls/useEnvs";
import { useSelector, useDispatch } from "react-redux";
import { setCurrentEnv } from "../../store/actions";

const { Option } = Select;

const SelectEnv = () => {
  const currentEnv = useSelector((state) => state.currentEnv);
  const dispatch = useDispatch();
  const { data: envList, isLoading: isEnvListLoading } = useEnvs();

  const handleSelectBarChange = (value) => {
    dispatch(setCurrentEnv(value));
  };

  if (isEnvListLoading) return <Spinner />;

  return (
    <div style={{ marginLeft: 11, marginRight: 11 }}>
      {(envList?.find((env) => env.id == currentEnv)).name}
    </div>
  )

  // DISABLED
  //return (
  //  <div>
  //    <Select
  //      value={currentEnv}
  //      style={{
  //        width: 120,
  //      }}
  //      onChange={handleSelectBarChange}
  //      bordered={false}
  //      suffixIcon={<SelectIcon />}
  //      placeholder="Environments"
  //      dropdownMatchSelectWidth={false}
  //    >
  //      {envList?.map((env) => {
  //        return (
  //          <Option key={env.id} value={env.id}>
  //            {env.name}
  //          </Option>
  //        );
  //      })}
  //    </Select>
  //    {/* {changeEnvLoading && <GlobalLoading />} */}
  //  </div>
  //);
};

export default SelectEnv;
