import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/img/atlas-ai-logo.png";
import { ReactComponent as Avatar } from "../../assets/img/avatar.svg";
import useOrganizationLogo from "../../hooks/calls/useOrganizationLogo";
import { AuthenticationButton } from "../Buttons/authentication-button";
import { TopLevelTabButton } from "../Buttons/StyledAntdButton";
import SaveView from "./SaveProject";
import Upload from "./Upload";
import SelectEnv from "./SelectEnv";
import SelectProjectView from "./SelectView";
import Spinner from "../Loading/Spinner";
import paths from "../../constants/paths";
import useGuards from "../../hooks/useGuards";

const Navbar = styled.div`
  background: #fff;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid #f6f8fa;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavItemsOuter = styled(NavContainer)`
  flex: 1;
`;

const NavItemsInnerLeft = styled(NavContainer)`
  margin-right: auto;
`;

const NavItemsInnerRight = styled(NavContainer)`
  margin-left: auto;
`;

const Header = (props) => {
  const { page } = props;
  const isEnvironmentsPage = page === "environments";
  const history = useHistory();
  const location = useLocation();
  const orgLogo = useOrganizationLogo();
  const { hasFeature, hasPermission } = useGuards();

  const leftItems = () => {
    return (
      <>
        <div
          onClick={() => {
            history.push(paths.yourEnvironments);
          }}
          className="pointer mr-16"
        >
          {orgLogo.isLoading ? (
            <Spinner />
          ) : (
            <img
              src={orgLogo.data}
              id="base64image"
              style={{ maxHeight: "30px" }}
              alt="company-logo"
            />
          )}

          <img
            src={logo}
            alt={"logo"}
            width={72}
            height={20}
            className="ml-14"
          />
        </div>
        {!isEnvironmentsPage && (
          <>
            <div>/</div>
            <SelectEnv />
            <div>/</div>
            <SelectProjectView />
          </>
        )}
      </>
    );
  };

  const centerItems = () => {
    if (isEnvironmentsPage) return;

    return (
      <>
        {hasFeature("DOCUMENTATION") && (
          <TopLevelTabButton
            type={"text"}
            onClick={() => {
              history.push(paths.documentation);
            }}
            $active={location.pathname === paths.documentation}
          >
            Documentation
          </TopLevelTabButton>
        )}
        {hasFeature("DASHBOARD") && (
          <TopLevelTabButton
            type={"text"}
            onClick={() => {
              history.push(paths.dashboard);
            }}
            $active={location.pathname === paths.dashboard}
          >
            Dashboard
          </TopLevelTabButton>
        )}
        <TopLevelTabButton
          type={"text"}
          onClick={() => {
            history.push("/visualization");
          }}
          $active={location.pathname === "/visualization"}
        >
          Visualization
        </TopLevelTabButton>

        <TopLevelTabButton
          type={"text"}
          onClick={() => {
            history.push("/data");
          }}
          $active={location.pathname === "/data"}
        >
          Data
        </TopLevelTabButton>
      </>
    );
  };

  const rightItems = () => {
    return (
      <>
        {!isEnvironmentsPage &&
          (hasPermission("di:MyProject:update") ||
            hasPermission("di:TeamProject:update")) && <SaveView />}
        {hasPermission("di:FileUpload:create") && (
          <div className="ml-12">
            <Upload />
          </div>
        )}
        <div className="nav-bar__actions ml-12">
          <AuthenticationButton />
        </div>
      </>
    );
  };

  return (
    <Navbar>
      <NavItemsOuter>
        <NavItemsInnerLeft>{leftItems()}</NavItemsInnerLeft>
      </NavItemsOuter>
      <NavItemsOuter>
        <NavContainer>{centerItems()}</NavContainer>
      </NavItemsOuter>
      <NavItemsOuter>
        <NavItemsInnerRight>{rightItems()}</NavItemsInnerRight>
      </NavItemsOuter>
    </Navbar>
  );
};

export default Header;
