import { theme } from "kepler.gl/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { createBrowserHistory } from "history";
import "./App.css";
import { ProtectedRoute } from "./components/protected-route";
import Dashboard from "./pages/Dashboard/Dashboard";
import Documentation from "./pages/Documentation/Documentation";
import DataExplorer from "./pages/DataExplorer";
import Home from "./pages/Home";
import { NotFound } from "./pages/not-found";
import Profile from "./pages/Profile";
import YourEnvironments from "./pages/YourEnvironments";
import CustomKeplerGl from "./pages/Home/CustomKeplerGl";
import paths from "./constants/paths";
import EnvironmentsManagement from "./components/EnvironmentsManagement";
import { ApolloWrapper } from "./ApolloWrapper";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
      cacheTime: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
      retry: true,
      retryDelay: (attempt, error) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ApolloWrapper>
          <Router>
            <div className="App">
              <EnvironmentsManagement />
              <Switch>
                <ProtectedRoute
                  exact
                  path={paths.yourEnvironments}
                  component={YourEnvironments}
                />
                <ProtectedRoute
                  exact
                  path={paths.documentation}
                  component={Documentation}
                />
                <ProtectedRoute
                  exact
                  path={paths.dashboard}
                  component={Dashboard}
                />
                <ProtectedRoute
                  exact
                  path={paths.profile}
                  component={Profile}
                />
                <ProtectedRoute
                  exact
                  path={paths.visualization}
                  component={Home}
                />
                <ProtectedRoute
                  exact
                  path={paths.data}
                  component={DataExplorer}
                />
                <Route path="*" component={NotFound} />
              </Switch>
              <CustomKeplerGl temp={true} />
            </div>
          </Router>
        </ApolloWrapper>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
