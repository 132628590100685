import React from "react";
import Tabs from "./Tabs";
const index = (props) => {
  return (
    <div className="pt-16">
      <Tabs
        toggleModal={props.uiStateActions.toggleModal}
        removeDataset={props.visStateActions.removeDataset}
        showDatasetTable={props.visStateActions.showDatasetTable}
        layerConfigChange={props.visStateActions.layerConfigChange}
        removeLayer={props.visStateActions.removeLayer}
        layerTypeChange={props.visStateActions.layerTypeChange}
        reorderLayer={props.visStateActions.reorderLayer}
        layerOrder={props.layerOrder}
        layerManager={props.layerManager}
        filterManager={props.filterManager}
        datasetManager={props.datasetManager}
        interactionManager={props.interactionManager}
        mapManager={props.mapManager}
      />
    </div>
  );
};

export default index;
