import React from "react";
import styled from "styled-components";
import Text from "../../../components/Text";
import ResourceCard from "./ResourceCard";
const Wrapper = styled.div`
  background: #f6f8fa;
  padding: 48px;
`;
const ResourcesCardWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
const Resources = () => {
  return (
    <Wrapper>
      <Text className="text19 mb-24">Resources</Text>

      <Text className="text16 mb-24">
        Links and references to help you make the most out of your experience
      </Text>
      <ResourcesCardWrapper>
        <ResourceCard
          title={"Documentation"}
          description={"Learn more about Atlas AI data"}
          href={"https://docs.atlasai.co/"}
        />
        <ResourceCard
          title={"View case studies"}
          description={"See how we can help"}
          href={"https://www.atlasai.co/research/case-studies"}
        />
      </ResourcesCardWrapper>
    </Wrapper>
  );
};

export default Resources;
