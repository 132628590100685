import { MapPopoverFactory } from "kepler.gl/components";

const CustomPanelHeaderFactory = (...deps) => {
  const Component = MapPopoverFactory(...deps);

  const CustomPanelHeader = (props) => {
    return (
      <Component
        {...props}
        layerHoverProp={{
          ...props.layerHoverProp,
          frozen: props.frozen,
        }}
      ></Component>
    );
  };
  return CustomPanelHeader;
};
CustomPanelHeaderFactory.deps = MapPopoverFactory.deps;
export default CustomPanelHeaderFactory;
