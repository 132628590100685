import React, { useState, useCallback } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import { ReactComponent as DatasetIcon } from "../../assets/img/dataset.svg";
import { ReactComponent as FilterIcon } from "../../assets/img/filter.svg";
import { ReactComponent as LayersIcon } from "../../assets/img/layers.svg";
import { ReactComponent as VectorIcon } from "../../assets/img/vector.svg";
import { ReactComponent as BaseMapIcon } from "../../assets/img/base-map.svg";
import { ReactComponent as InteractionIcon } from "../../assets/img/interaction.svg";

import Text from "../Text";
import {
  DataManagerWrapper,
  FilterManagerWrapper,
  InteractionManagerWrapper,
  LayerManagerWrapper,
  MapManagerWrapper,
} from "./wrapper-styles";
import { addFilter } from "kepler.gl/actions";
import { useDispatch, useSelector } from "react-redux";
import KeplerGlAddButton from "../Buttons/KeplerGlAddButton";
import useGuards from "../../hooks/useGuards";
import ExportDataModal from "../../pages/DataExplorer/Actions/ExportDataModal";

const { TabPane } = Tabs;

const SizeOfIcon = 18;

const TabList = (props) => {
  const dispatch = useDispatch();
  const datasets = useSelector(
    (state) => state.keplerGl.map?.visState.datasets
  );

  const { hasPermission } = useGuards();

  const [activeTab, setActiveTab] = useState("1");
  const [showExportDataModal, setShowExportDataModal] = useState(false);
  const setIsExportDataModalVisible = (bool) => {
    setShowExportDataModal(bool);
  };
  const operations = (
    <DownloadOutlined
      style={{ fontSize: SizeOfIcon, marginRight: 0, cursor: "pointer" }}
      onClick={() => {
        setIsExportDataModalVisible(true);
      }}
    />
  );
  const onTabClick = (key) => {
    setActiveTab(key);
  };
  const hadDataset = Object.keys(datasets).length;
  const onClickAddFilter = useCallback(() => {
    const defaultDataset =
      (Object.keys(datasets).length && Object.keys(datasets)[0]) || null;
    dispatch(addFilter(defaultDataset));
  }, [datasets, dispatch]);

  const setActiveColor = (idx, activeColor, inactiveColor) => {
    activeColor = activeColor !== undefined ? activeColor : "#1A60B4";
    inactiveColor = inactiveColor !== undefined ? inactiveColor : "#959DA5";
    return activeTab === idx ? activeColor : inactiveColor;
  }

  return (
    <div>
      <ExportDataModal
        isModalVisible={showExportDataModal}
        setIsModalVisible={setIsExportDataModalVisible}
      />
      <Tabs
        defaultActiveKey="1"
        onChange={onTabClick}
        tabBarExtraContent={operations}
        tabBarGutter={24}
        style={{ overflow: "visible" }}
      >
        <TabPane
          tab={
            <LayersIcon
              style={{
                fontSize: SizeOfIcon,
                marginRight: 0,
                fill: setActiveColor("1"),
              }}
            />
          }
          key="1"
        >
          <div>
            <Text className={"text3 mb-24"}>Map Layers</Text>
            <LayerManagerWrapper
              canAdd={
                hasPermission("di:TeamProject.Layer:create") ||
                hasPermission("di:MyProject.Layer:create")
              }
            >
              {props.layerManager}
            </LayerManagerWrapper>
          </div>
        </TabPane>
        <TabPane
          tab={
            <FilterIcon
              style={{
                fontSize: SizeOfIcon,
                marginRight: 0,
                fill: setActiveColor("2"),
              }}
            />
          }
          key="2"
        >
          <div>
            <div className={"flex-b-c  mb-24"}>
              <Text className={"text3"}>Filters</Text>
              <KeplerGlAddButton
                onClick={onClickAddFilter}
                inactive={!hadDataset}
              >
                Add filter
              </KeplerGlAddButton>
            </div>

            <FilterManagerWrapper>{props.filterManager}</FilterManagerWrapper>
          </div>
        </TabPane>
        <TabPane
          tab={
            <DatasetIcon
              style={{
                fontSize: SizeOfIcon,
                marginRight: 0,
                fill: setActiveColor("3"),
              }}
            />
          }
          key="3"
        >
          <div>
            <Text className={"text3 mb-24"}>Datasets</Text>

            <DataManagerWrapper
              canAdd={
                hasPermission("di:TeamProject.Dataset:create") ||
                hasPermission("di:MyProject.Dataset:create")
              }
              canDelete={
                hasPermission("di:TeamProject.Dataset:delete") ||
                hasPermission("di:MyProject.Dataset:delete")
              }
            >
              {props.datasetManager}
            </DataManagerWrapper>
          </div>
        </TabPane>
        <TabPane
          tab={
            <InteractionIcon
              style={{
                fontSize: SizeOfIcon,
                marginRight: 0,
                fill: setActiveColor("4"),
              }}
            />
          }
          key="4"
        >
          <div>
            <Text className={"text3 mb-24"}>Interactions</Text>

            <InteractionManagerWrapper>
              {props.interactionManager}
            </InteractionManagerWrapper>
          </div>
        </TabPane>
        <TabPane
          tab={
            <BaseMapIcon
              style={{
                fontSize: SizeOfIcon,
                marginRight: 0,
                fill: setActiveColor("5"),
              }}
            />
          }
          key="5"
        >
          <div>
            <Text className={"text3 mb-24"}>Base Map</Text>
            <MapManagerWrapper>{props.mapManager}</MapManagerWrapper>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TabList;
