import config from "./config";
import {
  decodeJson,
  parseStreamedGeoJsonResponse,
} from "./tools";
import { appendToKeplerDataset, processDataForKepler } from "./processors";
import { apolloClient } from "../ApolloWrapper";
import { GET_Signed_Urls_Of_Discovery } from "../Queries/discovery";

export const isDiscoveryUri = (uri) => {
  return uri.startsWith("discovery/");
}

export const assembleDiscoveryUrl = (uri) => {
  const discoveryUrl = new URL(config.graphQlRoot);
  discoveryUrl.pathname = uri;
  return discoveryUrl.href;
}

export const assembleDiscoveryDatasetRequest = (uri, bearerToken) => {
  return {
    type: 'discovery',
    id: uri,
    request: new Request(
      assembleDiscoveryUrl(uri),
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${bearerToken}`,
        },
      }
    )
  };
}

export const findDiscoveryReleaseUri = (uris) => {
  return uris.find((uri) => uri.startsWith("discovery/release/")) || uris[0];
}

export const parseDiscoveryTags = (tags) => {
  return tags?.map((tag) => `${tag.name}:${tag.value}`) || [];
}

export const isDiscoveryFullSearchResult = (response) => {
  return response.__typename === "FullSearchResult";
}

export const assembleDiscoveryDataset = (product, release) => {
  const uri = findDiscoveryReleaseUri(release.uris);
  const label = (
    release.name ||
    product.displayName || product.display_name ||
    product.internalName || product.internal_name
  );

  let type = "csv";
  let rawData = {
    __fromDiscovery: true,
    description: release.description,
    id: uri,
    uris: release.uris,
    version: release.version,
    productInternalName: product.internalName || product.internal_name,
    productDescription: product.description,
    internalName: product.internalName || product.internal_name,
    displayName: product.displayName || product.display_name,
    releaseId: release.id,
    releaseName: release.name,
    license: release.license,
    reference: decodeJson(product.reference),
    dataSteward: decodeJson(product.dataSteward || product.data_steward),
    tags: parseDiscoveryTags(release.tags),
    createDate: release.createDate || release.create_date,
    modifyDate: release.modifyDate || release.modify_date,
  };

  if (release.extent) {
    type = "json";
    rawData = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          geometry: decodeJson(release.extent),
          properties: rawData,
        },
      ],
    };
  }

  return {
    id: uri,
    type: type,
    label: label,
    data: rawData,
  };
};

export const extractLoadableDiscoveryAssets = (response, releaseId) => {
  const assets = [];
  const instanceIds = (
    releaseId !== undefined
      ? response.product.releases.find((release) => release.id === releaseId).instanceIds
      : null
  );

  response.instances?.forEach((instance) => {
    if (instanceIds !== null && instanceIds.indexOf(instance.id) === -1) return;

    instance.assets?.forEach((asset) => {
      const metadata = (
        typeof asset.metadata === 'string'
          ? JSON.parse(asset.metadata)
          : asset.metadata
      );
      if (asset.type === 'Vector' && metadata.Vector.driver === 'GeoJSON')
        assets.push(asset);
    });
  });

  return assets;
}

export const includeLoadableDiscoveryAssets = async (props) => {
  const { keplerDataset, response, label, releaseId } = props;
  try {
    const assets = extractLoadableDiscoveryAssets(response, releaseId);
    const downloadUrls = await Promise.all(
      assets.map((asset) => apolloClient.query({
        query: GET_Signed_Urls_Of_Discovery,
        variables: {
          input: asset.uris[0]
        },
      }))
    );
    const allAssetData = await Promise.all(
      downloadUrls.map(
        (resp) => resp.data.download.map((url) => fetch(url))
      ).flat()
    );

    for (const resp of allAssetData) {
      try {
        const loadedAssetData = processDataForKepler({
          fileType: 'json',
          dataset: await parseStreamedGeoJsonResponse(resp),
          datasetName: label,
        });
        appendToKeplerDataset(keplerDataset, loadedAssetData);
      }
      catch (e) {
        console.error('Error appending loadable Discovery Asset', label, keplerDataset, resp);
        console.error(e);
      }
    }
  }
  catch (e) {
    console.error('Error including loadable Discovery Assets', label, keplerDataset, response);
    console.error(e);
  }
}
