import React, { useState } from "react";
import ExportData from "./ExportData";
import DatasetDetails from "./DatasetDetails";

const Actions = (props) => {
  const { isExportDataDisable } = props;

  return (
    <div style={{ display: "flex", justifyContent: "end", marginBottom: 8 }}>
      <ExportData disable={isExportDataDisable} />
      <DatasetDetails />
    </div>
  );
};

export default Actions;
