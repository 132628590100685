import { Select } from "antd";
import React from "react";
import Text from "../../components/Text";
const { Option } = Select;

const SelectDatasetUrl = (props) => {
  const { list, onChange, className } = props;
  return (
    <div className={className}>
      <Select
        placeholder="Select dataset"
        onChange={onChange}
        optionLabelProp="label"
        style={{ width: "100%" }}
      >
        {list?.map((data, i) => {
          return (
            <Option value={data.id} key={i} label={data.name}>
              <Text className={"ml-8 lowercase"}>{data.name}</Text>
            </Option>
          );
        })}
      </Select>
    </div>
  );
};

export default SelectDatasetUrl;
