import React, { useState } from "react";
import { useDatasetList } from "../../hooks/calls/useDatasetList";
import { Loader } from "../../components/loader";
import SelectDatasetUrl from "./SelectDatasetUrl";
import useLoadMap from "../../hooks/useLoadMap";
import StyledButton from "../../components/Buttons/StyledAntdButton";
import styled from "styled-components";
import GlobalLoading from "../../components/GlobalLoading";
import { useSelector } from "react-redux";
import useAddDatasetToMap from "../../hooks/useAddDatasetToMap";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  div {
    flex: 1;
  }
  button {
    align-self: flex-end;
  }
`;

const LoadDatasets = () => {
  const currentEnv = useSelector((state) => state.currentEnv);
  const res = useDatasetList(currentEnv);
  const { getFile } = useLoadMap();
  const [selectedUrl, setSelectedUrl] = useState("");
  const [isDatasetLoading, setIsDatasetLoading] = useState(false);
  const addDatasetToMap = useAddDatasetToMap();

  if (!res.isFetched) {
    return <Loader></Loader>;
  }
  const onChange = (url) => {
    setSelectedUrl(url);
  };
  const onSubmit = () => {
    const datasets = res.data.datasets;
    const selectedDataset = datasets.find(
      (dataset) => dataset.id === selectedUrl
    );
    getFile({
      dataUrl: selectedDataset.url,
      onStart: () => setIsDatasetLoading(true),
      onFinish: () => setIsDatasetLoading(false),
      callback: (datasetFile, fileType) => {
        addDatasetToMap(
          fileType,
          datasetFile,
          selectedDataset.id,
          selectedDataset.name
        );
      },
    });
  };
  return (
    <Wrapper>
      <SelectDatasetUrl list={res.data?.datasets || []} onChange={onChange} />
      {isDatasetLoading && <GlobalLoading />}

      <StyledButton type={"primary"} onClick={onSubmit} disabled={!selectedUrl}>
        Add Dataset
      </StyledButton>
    </Wrapper>
  );
};

export default LoadDatasets;
