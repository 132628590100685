import React from "react";
import { Button } from "antd";
import ChartInput from "./ChartInput";
import MultiSelect from "./MultiSelect";
import styled from "styled-components";
import { setFilter } from "kepler.gl/actions";
import { useDispatch } from "react-redux";
import TimeInput from "./TimeInput";

const Footer = styled.div`
  display: flex;
  justify-content: end;
  gap: 12px;
`;

const FieldValue = (props) => {
  const dispatch = useDispatch();

  const { filter, closePopup, filters } = props;
  const [value, setValue] = React.useState(filter.value);
  const onCancelClick = () => {
    closePopup();
  };
  const onApplyClick = () => {
    dispatch(setFilter(filters.length - 1, "value", value));
    closePopup();
  };
  return (
    <div>
      {filter?.type === "range" && (
        <ChartInput
          className={"mb-16"}
          filters={filters}
          value={value}
          setValue={setValue}
        />
      )}
      {filter?.type === "multiSelect" && (
        <MultiSelect
          className={"mb-16"}
          filters={filters}
          value={value}
          setValue={setValue}
        />
      )}
      {filter?.type === "timeRange" && (
        <TimeInput
          className={"mb-16"}
          filters={filters}
          value={value}
          setValue={setValue}
        />
      )}
      <Footer>
        <Button type="text" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button type="primary" onClick={onApplyClick}>
          Apply
        </Button>
      </Footer>
    </div>
  );
};

export default FieldValue;
