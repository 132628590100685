const theme = {
  sidePanelBg: "#ffffff",
  titleTextColor: "#000000",
  sidePanelHeaderBg: "#f7f7F7",
  subtextColorActive: "#2473bd",
  tooltipBg: "#1869b5",
  tooltipColor: "#ffffff",
  dropdownListBgd: "#ffffff",
  textColorHl: "#2473bd",
  inputBgd: "#f7f7f7",
  inputBgdHover: "#ffffff",
  inputBgdActive: "#ffffff",
  dropdownListHighlightBg: "#f0f0f0",
  panelBackground: "#f7f7F7",
  secondaryInputBgd: "#f7f7F7",
  secondaryInputBgdActive: "#f7f7F7",
  secondaryInputBgdHover: "#ffffff",
  panelActiveBg: "#f7f7F7",
  fontFamily: "inter",
  fontSize: "14px",
  panelContentBackground: "#fff",
  //button
  btnFontFamily: "inter",
  //primary button
  primaryBtnFontSizeDefault: "1em",
  primaryBtnBgd: "transparent",
  primaryBtnColor: "#1A60B4",
  primaryBtnBgdHover: "#1A60B4",
  primaryBtnBorder: "1px solid #1A60B4",
  //secondary button
  secondaryBtnBgd: "transparent",
  secondaryBtnColor: "#1A60B4",
  secondaryBtnBgdHover: "#1A60B4",
  secondaryBtnBorder: "1px solid #1A60B4",
  // slider
  sliderBarBgd: "#f0f0f0",

  // Switch
  switchTrackBgd: "#D1D5DA",
  switchTrackBgdActive: "#1A60B4",
  switchBtnBgd: "#ffffff",
  switchBtnBgdActive: "#ffffff",
  switchWidth: 24,
  switchHeight: 12,
  switchLabelMargin: 12,
  switchTrackBorderRadius: "16px",
  switchBtnBorderRadius: "50%",
  // input
  floatingBtnActBgd: "#ffffff",
  floatingBtnBgdHover: "#ffffff",
  toolbarItemBgdHover: "#ffffff",
  panelBackgroundHover: "#ffffff",
  chickletBgd: "#ffffff",
  // fontSize original 10
  primaryBtnFontSizeSmall: "14px",
  inputFontSizeSmall: "14px",
  tooltipFontSize: "14px",
  sliderInputFontSize: "14px",
  axisFontSize: "14px",
  timeTitleFontSize: "14px",
  cellFontSize: 14,
  // font size original 11
  inputFontSize: "15px",
  selectFontSize: "15px",
};
export default theme;
