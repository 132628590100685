import React from "react";
import { Loader } from "../loader";
import styled from "styled-components";
const Wrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 101;
  transform: translate(-50%, -50%);
`;
const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;
const index = () => {
  return (
    <>
      <Wrapper>
        <Loader />
      </Wrapper>
      <Overlay />
    </>
  );
};

export default index;
