import { enhanceReduxMiddleware } from "kepler.gl/middleware";
import keplerGlReducer from "kepler.gl/reducers";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { createLogger } from "redux-logger";
import { envsReducer } from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const logger = createLogger({
  diff: true,
});
const initialState = {};

const DEFAULT_LAYER_GROUPS = [
  {
    slug: "label",
    filter: ({ id }) => id.match(/(?=(label|place-|poi-))/),
    defaultVisibility: true,
  },
  {
    slug: "road",
    filter: ({ id }) =>
      id.match(/(?=(road|railway|tunnel|street|bridge))(?!.*label)/),
    defaultVisibility: true,
  },
  {
    slug: "border",
    filter: ({ id }) => id.match(/border|boundaries/),
    defaultVisibility: false,
  },
  {
    slug: "building",
    filter: ({ id }) => id.match(/building/),
    defaultVisibility: true,
  },
  {
    slug: "water",
    filter: ({ id }) => id.match(/(?=(water|stream|ferry))/),
    defaultVisibility: true,
  },
  {
    slug: "land",
    filter: ({ id }) =>
      id.match(/(?=(parks|landcover|industrial|sand|hillshade))/),
    defaultVisibility: true,
  },
  {
    slug: "3d building",
    filter: () => false,
    defaultVisibility: false,
  },
];

const customizedKeplerGlReducer = keplerGlReducer.initialState({
  mapStyle: {
    styleType: "light",
  },
  uiState: {
    readOnly: false,
    currentModal: null,
    // currentModal: "exportData",
    mapControls: {
      mapDraw: {
        active: false,
        activeMapIndex: 0,
        disableClose: false,
        show: false,
      },
      mapLegend: {
        active: false,
        activeMapIndex: 0,
        disableClose: false,
        show: true,
      },
      mapLocale: {
        active: false,
        activeMapIndex: 0,
        disableClose: false,
        show: false,
      },
      splitMap: {
        active: false,
        activeMapIndex: 0,
        disableClose: false,
        show: false,
      },
      toggle3d: {
        active: false,
        activeMapIndex: 0,
        disableClose: false,
        show: false,
      },
      visibleLayers: {
        active: false,
        activeMapIndex: 0,
        disableClose: false,
        show: false,
      },
    },
  },
});
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["currentEnv"], // only navigation will be persisted
};

const reducers = combineReducers({
  keplerGl: customizedKeplerGlReducer,
  currentEnv: envsReducer,
});

const middlewares = enhanceReduxMiddleware([]);
// const enhancers = [applyMiddleware(...middlewares, logger)];
const enhancers = [applyMiddleware(...middlewares)];
const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(...enhancers)
);
export default store;
// keplerGl.map.visState.layers;
export const persistor = persistStore(store);
