export const redirectIfAnyCartoProject = (envList) => {
  if (!envList) return;

  // if any env is on Carto, all must be on Carto
  const requiresCarto = envList.some(
    (env) => env.cartoMapId !== undefined && env.cartoMapId !== null
  );
  const thisUrl = new URL(document.URL);
  if (requiresCarto === true) {
    console.log('Org requires Carto');
    const cartoUrl = process.env.REACT_APP_INTELLIGENCE_CARTO_URL;
    const cartoUrlObj = new URL(cartoUrl);

    if (thisUrl.hostname !== cartoUrlObj.hostname) {
      if (process.env.NODE_ENV === 'production') {
        console.debug(`Redirecting to Carto: ${cartoUrl}`);
        if (process.env.REACT_APP_DISABLE_CARTO_REDIRECT) {
          console.debug(`Instructed not to redirect to Carto: ${cartoUrl}`);
        }
        else {
          window.location.href = cartoUrl;
        }
      }
      else {
        console.debug(`Not in Production. Not redirecting to Carto: ${cartoUrl}`);
      }
    }
  }
  else {
    console.log('Org requires Kepler');
    const baseUrl = process.env.REACT_APP_INTELLIGENCE_KEPLER_URL;
    const baseUrlObj = new URL(baseUrl);

    if (thisUrl.hostname !== baseUrlObj.hostname) {
      if (process.env.NODE_ENV === 'production') {
        console.debug(`Redirecting to Kepler: ${baseUrl}`);
        if (process.env.REACT_APP_DISABLE_CARTO_REDIRECT) {
          console.debug(`Instructed not to redirect to Kepler: ${baseUrl}`);
        }
        else {
          window.location.href = baseUrl;
        }
      }
      else {
        console.debug(`Not in Production. Not redirecting to Kepler: ${baseUrl}`);
      }
    }
  }
}
