import React, { useState } from "react";
import All from "./All";
import CheckValidity from "./CheckValidity";

const Dataset = (props) => {
  const { config, currentEnvId } = props;
  const [dsRefs, setDsRefs] = useState(
    config.datasets || []
  );

  const onSetDsRefs = (newDsRefs) => {
    setDsRefs(newDsRefs);
  };

  return (
    <div>
      <All config={config} dsRefs={dsRefs} currentEnvId={currentEnvId} />
      <CheckValidity setDsRefs={onSetDsRefs} />
    </div>
  );
};

export default Dataset;
