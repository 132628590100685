import React from "react";
import { ModalComponent, StyledModal, downloadCsv } from "./ExportDataModal";
import { Button } from "antd";
import { useQuery } from "@apollo/client";
import { GET_Signed_Urls_Of_Discovery } from "../../../Queries/discovery";
import Spinner from "../../../components/Loading/Spinner";
import { isGeojsonDiscovery, parseUri } from "../../../utils/tools";

const DiscoveryPreview = (props) => {
  const { visible, onSetVisible, selectedDiscovery } = props;
  const urilsPlace = isGeojsonDiscovery(selectedDiscovery) ? 4 : 3;

  let uris = selectedDiscovery.dataContainer._rows[0][urilsPlace];
  uris = typeof uris === "string" ? JSON.parse(uris) : uris;
  const response = useQuery(GET_Signed_Urls_Of_Discovery, {
    variables: {
      input: uris[0],
    },
  });
  let content = "";
  if (response.loading) content = <Spinner size={"medium"} />;
  if (response.data?.download) {
    const signedUrls = response.data.download;
    content = signedUrls.map((url) => {
      const { file: fileName } = parseUri(url);
      return (
        <div key={url} className="p-8">
          <a href={url} target={"_blank"} rel="noreferrer">
            {fileName}
          </a>
        </div>
      );
    });
  }
  const onCancel = () => {
    onSetVisible(false);
  };
  const onDownload = () => {
    const links = "signed_url\n" + response.data.download.join("\n");
    downloadCsv(links, selectedDiscovery.label + ".csv");
  };
  return (
    <div>
      <StyledModal
        title="Preview Discovery"
        visible={visible}
        width={"50%"}
        onCancel={() => onSetVisible(false)}
        footer={[
          <Button key="cancel-preview" onClick={onCancel}>
            Cancel
          </Button>,

          <Button key="preview" type="primary" onClick={onDownload}>
            Download List
          </Button>,
        ]}
      >
        <ModalComponent>{content}</ModalComponent>
      </StyledModal>
    </div>
  );
};

export default DiscoveryPreview;
