export const ignoredColumns = [
    "lng",
    "lat",
    "longitude",
    "latitude",
    "X",
    "Y",
    "geojson",
    "_geojson",
    "geometry",
]
